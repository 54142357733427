/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LoginWithEmail from "./LoginWithEmail";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import loginImage5_8 from "../../Components/Common/images/LoginImages/5-8.webp";
import loginImage9_10 from "../../Components/Common/images/LoginImages/9-10.webp";
import loginImage11_12 from "../../Components/Common/images/LoginImages/11-12.webp";
import HeaderOne from "../../Components/HeaderOne/Header/headerone";
import FooterOne from "../../Components/HeaderOne/Footer/footerone";
import HeaderTwo from "../../Components/HeaderTwo/Header/headertwo";
import HeaderThree from "../../Components/HeaderThree/header/headerthree";
import FooterTwo from "../../Components/HeaderTwo/Footer/footertwo";
import FooterThree from "../../Components/HeaderThree/footer/footerthree";
import LoginWithMobile from "./LoginWithMobile";
import Loader from "../../Components/Loader/Loader";
import { GetLocalStorage } from "../../Components/utils/utils";
// import Header from "../Header/Header";
// import Footer from "../Footer/Footer";

const Login = () => {
  const path = window.location.pathname;
  const navigate = useNavigate()
  const location = useLocation();
  const loginPath = location?.state;
  let [url, setUrl] = useState(path || loginPath)
  const [loading, setloading] = useState(false);

  const navigatetoSignup = () => {
    if (path?.endsWith("/5-8")) {
      navigate("/signup", { state: "5-8" })
    } else if (path?.endsWith("/9-10")) {
      navigate("/signup", { state: "9-10" })
    } else if (path?.endsWith("/11-12")) {
      navigate("/signup", { state: "11-12" })
    } else {
      navigate("/signup", { state: "5-8" })
    }
  }
  useEffect(() => {
    url = url !== undefined ? url : "";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  useEffect(() => {
    const pathname = window.location.pathname?.toLowerCase();
    const UserProfile = JSON.parse(GetLocalStorage("UserProfile"));
    if (UserProfile && pathname?.endsWith("/" || "/app/")) {
      UserProfile?.is_payment_done === "DN" ? navigate("/dashboard") : navigate("/SchoolDetails");
      setTimeout(() => {
        toast.error("User Already Logged In")
      }, 50);
    }
  }, [])

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        draggable
        bodyClassName="fs-4"
        theme="colored"
      />
      {
        loading ? <Loader /> : null
      }
      {
        url?.endsWith("/5-8") ? <HeaderOne /> : url?.endsWith("/9-10") ? <HeaderTwo /> : url?.endsWith("/11-12") ? <HeaderThree /> : <HeaderOne />
      }

      <section className="tf-section tf-message signup-login">
        <div className="container-fluid main-container-pad">
          <div className="row">
            <div className="col-md-12">
              <div className="form-message section-signup-cstm login-cstm-2">
                <div className="col-md-6" id="image-login">
                  <section className="login-image-section">
                    <img src={url?.endsWith("/5-8") ? loginImage5_8 : url?.endsWith("/9-10") ? loginImage9_10 : loginImage11_12} onError={(e) => { url?.endsWith("/5-8") ? e.target.src = "images/5-8.webp" : url?.endsWith("/9-10") ? e.target.src = "images/9-10.webp" : e.target.src = "images/11-12.webp" }} alt="" className="login-image-main" />
                  </section>
                </div>
                <div className="col-md-6" id="mobile-login">
                  <div className="login-form-div-1 main-section-signup">
                    <h2 className="heading heading-create-acc">Sign In</h2>
                    <Tabs defaultActiveKey="LoginWithMobile" transition={false}
                      id="noanim-tab-example" className="mb-3 tabs-login" >
                      <Tab eventKey="LoginWithMobile" title="Mobile No" className="postAdd_btn_2" >
                        <LoginWithMobile path={url} setloading={setloading} />
                      </Tab>
                      <Tab eventKey="LoginWithEmail" title="Username" className="postAdd_btn_1" >
                        <LoginWithEmail path={url} setloading={setloading} />
                      </Tab>
                    </Tabs>

                    <p className="butt-p acn_dont">
                      Don't have an account? Please&nbsp;
                      <span className="link-login" onClick={navigatetoSignup}>
                        Register
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        url?.endsWith("/5-8") ? <FooterOne /> : url?.endsWith("/9-10") ? <FooterTwo /> : url?.endsWith("/11-12") ? <FooterThree /> : <FooterOne />
      }
    </>
  );
};

export default Login;

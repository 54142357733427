/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./ExamTest.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import {
  ApiUrl,
  GetLocalStorage,
  RemoveLocalStorage,
  SetLocalStorage,
} from "../../utils/utils";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Slider from "react-slick";
import moment from "moment";
import Pay from "../../../pages/pay/pay";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { MdClose } from "react-icons/md";
const ExamTest = ({ examRef }) => {
  const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
  const [examList, setExamList] = useState([]);
  const [selectedExamtId, setSelectedExamIdId] = useState(null);
  const exam_result_date = examList?.find(
    (item) => item?.exam_id === selectedExamtId
  )?.exam_result_date;
  const [show1, setShow1] = useState(false);
  // const [resultId, setResultId] = useState();
  // const resultIDID = SetLocalStorage("resultId", JSON.stringify(resultId));
  const [show, setShow] = useState(false);
  const closePay = () => { html.style.overflow = "unset"; setShow(false); }
  const showPay = (item) => {
    setShow(true);
    html.style.overflow = "hidden";
    setOrderData(item);
  };
  const [orderData, setOrderData] = useState();
  const [examDataFind, setExamDataFind] = useState();
  const [exam, setExam] = useState();
  const [resultModal, setresultModal] = useState(false);
  const [resultModalDate, setresultModalDate] = useState(false);
  const [viewResultModal, setViewResultModal] = useState(false);
  const pathName = `${window.location.pathname?.toLowerCase()}`;
  const html = document.querySelector("html");

  const ExamList = () => {
    const data = {};
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/start_quiz.php?request=get_exam_list`,
    };
    axios(option)
      .then((e) => {
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
        }
        if (e?.data?.result_code === "OK") {
          setExamList(e.data.result_data.data);
        }
      })
      .catch((err) => { });
  };
  useEffect(() => {
    ExamList();
    RemoveLocalStorage("result_date");
    const path1 = window.location.search;
    if (path1 && path1.includes("status=failed")) {
      toast.error("Payment Failed.");
    } else if (path1 && path1.includes("status=done")) {
      toast.success("Payment Done.");
    }
    RemoveLocalStorage("Exam");
    RemoveLocalStorage('result_data')
    RemoveLocalStorage('totalQuestions')
  }, []);
  const closeResult = () => {
    html.style.overflow = "unset";
    setresultModal(false);
    setresultModalDate("");
  };
  const closeViewResult = () => { html.style.overflow = "unset"; setViewResultModal(false) };

  const handleExamClick = (item) => {
    setExam(item);
    if (item.exam_attempted === 1 && item?.exam_result?.result_id) {
      setViewResultModal(true);
      html.style.overflow = "hidden";
    } else if (item.exam_attempted === 1) {
      setresultModal(true);
      html.style.overflow = "hidden";
      setresultModalDate(item?.exam_result_date);
    } else if (
      moment(new Date()).format("MMM. DD, YYYY") >
      moment(item?.exam_to).format("MMM. DD, YYYY")
    ) {
      return;
    } else if (item.payment_status === "DN" || item.exam_payment <= 0) {
      setSelectedExamIdId(item.exam_id);
      setShow1(true);
      html.style.overflow = "hidden";
    } else {
      handleSubmitAmount(item);
    }
  };

  const handleSubmitAmount = (item) => {
    const data = {};
    data.exam_id = item?.exam_id;
    // setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/signup.php?request=initialize_exam_payment`,
    };
    axios(option)
      ?.then((e) => {
        if (e?.data?.result_data) {
          showPay(e?.data?.result_data);
        }
        // setloading(false)
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
      })
      ?.catch((err) => {
        // setloading(false)
      });
  };
  const handleClose1 = () => { html.style.overflow = "unset"; setShow1(false); }
  const ExamQues = async () => {
    // if (GetLocalStorage("Exam")) {
    //   toast.error("Exam Already Attempted");
    //   return;
    // }
    const data = {};
    data.exam_id = selectedExamtId;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/start_quiz.php?request=start_quiz_exam`,
    };
    try {
      const response = await axios(option);
      if (
        response?.data?.result_code === "FAILED" ||
        response?.data?.result_code === "ERROR"
      ) {
        toast.error(response?.data?.message);
      }
      if (response?.data?.message === "Exam Already Attempted") {
        toast.error("Exam Already Completed");
        setShow1(false);
      }
      if (response?.data?.result_code === "OK") {
        // Save the result data to local storage
        // setResultId(response.data.result_data.result_id);
        SetLocalStorage("resultId", response.data.result_data.result_id);
        setShow1(false);
        SetLocalStorage("result_date", JSON.stringify(exam_result_date));
        SetLocalStorage("Exam", JSON.stringify(response.data.result_data));
        SetLocalStorage(
          "totalQuestions",
          JSON.stringify(response?.data?.result_data?.data?.length)
        );
        // Open the quiz page in a new window
        const quizUrl = "exam";
        const windowFeatures =
          "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=" +
          window.screen.availWidth +
          ",height=" +
          window.screen.availHeight;
        window.open(quizUrl, "_blank", windowFeatures);
        html.style.overflow = "unset";
      }
    } catch (error) {
      // handle error
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="dashexamtest" ref={examRef}>
        <div className="container">
          <div className="dash-examsection container">
            <h1 className="heading">Exams</h1>
            <p className="dash-exam-para">
              When the time comes to take the actual exam, rest assured that our
              platform maintains strict security measures to ensure the
              integrity and confidentiality of your test. Your exam experience
              will be smooth and reliable, giving you the best opportunity to
              demonstrate your knowledge and skills.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 dash-exam-slider">
              <Slider {...settings}>
                {examList?.length ? (
                  examList?.map((item, index) => {
                    const examFrom = moment(item?.exam_from).format(
                      "MMM. DD, YYYY"
                    );
                    const examTo = moment(item?.exam_to).format(
                      "MMM. DD, YYYY"
                    );
                    return (
                      <div key={index} className="dash-examcols row">
                        <div className="dash-exam-card1 col-6">
                          <img src="images/dashboard/dash-exam.webp" alt="exm" />
                        </div>
                        <div className="dash-exam-card2 col-6">
                          <h1 className="card2-heading">{item?.title}</h1>
                          <p className="card2-para">{item?.description}</p>
                          <div className="dash-exam-number-cont">
                            <div className="dash-exam-number">
                              <p>
                                Total Questions:{" "}
                                <span>{item?.total_questions}</span>
                              </p>
                              {/* <p>Exam Duration: <span>30min<span style={{ color: "red" }}>(P)</span></span></p> */}
                              <p>
                                Date: <span>{`${examFrom} - ${examTo}`}</span>
                              </p>
                              <p>
                                Exam Type: <span>{item?.exam_type}</span>
                              </p>
                              <p>
                                Exam Fee: <span> Rs. {item?.exam_payment}</span>
                              </p>
                            </div>
                            <div
                              className="dash-exam-start"
                              onClick={() => handleExamClick(item)}
                            >
                              {item.exam_attempted === 1
                                ? "View Result"
                                : moment(new Date()).format("MMM. DD, YYYY") >
                                  examTo
                                  ? "Exam End"
                                  : item.payment_status === "DN" ||
                                    item.exam_payment <= 0
                                    ? "Start Exam"
                                    : "Buy Now"}
                              <HiArrowNarrowRight />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="dash-examcols row">
                    <div className="dash-exam-card1 col-6">
                      <img
                        src="images/dashboard/dash-exam.webp"
                        alt="exm"
                        className="no-exam-image"
                      />
                    </div>
                    <div className="dash-exam-card2 col-6">
                      <h1 className="card2-heading-no-exams">
                        No Exams <br />
                        <span>Available at</span>
                        <br />
                        The Moment.
                      </h1>
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>

        {/* Rules & Regulation Modal */}
        <Modal show={show1} onHide={handleClose1} className="home-model">
          <Modal.Header className="home-model-header">
            <Modal.Title className="home-modal-title">
              <div className="modal-heading">
                <p>Exam </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p id="rules-regulation">
              <strong>Rules & Regulation:</strong>
            </p>
            <p>
              <strong>1: Technical Requirements:</strong> Inform students in
              advance about the technical requirements for taking the online
              mock test. This may include a stable internet connection,
              compatible devices (laptops, tablets), and recommended browsers.
            </p>
            <p>
              <strong>2: Time Limit:</strong> Clearly state the time limit for
              the test. Implement an automatic timer within the test platform to
              enforce the time constraint.
            </p>
            <p>
              <strong>3: No External Assistance:</strong> Prohibit students from
              seeking external help during the test. They should complete the
              test independently, just as they would in an offline exam.
            </p>
            <p>
              <strong>4: Technical Support:</strong> Provide a helpline or
              contact person for technical assistance in case students encounter
              any issues during the test.
            </p>
          </Modal.Body>
          <Modal.Footer className="home-modal-footer">
            <Button className="fl-btn st-6" disabled={GetLocalStorage('Exam')} onClick={ExamQues}>
              <span className="inner">Start Exam</span>
            </Button>
          </Modal.Footer>
        </Modal>

        {/* payment popup */}
        <Modal show={show} onHide={closePay} backdrop="static" keyboard={false}>
          <Pay setShow={setShow} orderData={orderData} examDataFind={exam}
            failedUrl={pathName?.endsWith("/dashboard") ? "home" : "signup"} />
        </Modal>

        {/* result date modal */}
        <Modal show={resultModal} onHide={closeResult}>
          <Modal.Body className="payForm">
            <div className="closeBtn" onClick={() => closeResult()}>
              <img src="images/close (1).webp" alt="" />
            </div>
            <div className="row payment">
              <div className="col-md-12">
                <div className="headLine">Exam Result</div>
                <div className="underLine"></div>
              </div>
              <div className="col-md-12 mb-2">
                <h3 className="text-black-head-pay fw-semibold examtest-resultm">
                  <span className="examtest-moment">
                    Congratulations!
                  </span> You have completed the exam successfully.
                  <br /><br />
                  Your exam result will be out on{" "}
                  <span className="examtest-moment">
                    {moment(resultModalDate).format("DD-MM-YYYY")}
                  </span>
                  .
                </h3>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* View result % modal */}
        <Modal show={viewResultModal} onHide={closeViewResult} className="exam_result_modal" >
          <Modal.Body>
            <div className="closeBtn_modal" onClick={() => closeViewResult()} >
              <MdClose className="cross_icon_modal" />
            </div>
            <div className="result_modal_backdrop">
              <div className="modal_percentage_check">
                <p className="modal_percentage">{((exam?.exam_result?.correct_answers / exam?.total_questions) * 100) === 0 ?
                ((exam?.exam_result?.correct_answers / exam?.total_questions) * 100) :
                ((exam?.exam_result?.correct_answers / exam?.total_questions) * 100).toFixed(1)}% </p>
                <img src="images/yellow_check.png" alt="" />
              </div>
              <p className="modal_congrats">{((exam?.exam_result?.correct_answers / exam?.total_questions) * 100).toFixed(1) <= 0 ? null : 'Congratulations!!'}</p>
              <p className="modal_pass_msg">{((exam?.exam_result?.correct_answers / exam?.total_questions) * 100).toFixed(1) <= 0 ? "You have Failed the Exam" : "You have Passed the Exam"}</p>
            </div>
            <div className="modal_straight_div">
              <div className="modal_straight_line"></div>
            </div>
            <div className="result_modal_second_section">
              <div className="result_modal_second_first_column">
                <p className="modal_ques_attempt">Questions Attempted</p>
                <p className="modal_marks modal_quiz_mock_marks">{exam?.exam_result?.correct_answers + exam?.exam_result?.incorrect_answers}/{exam?.total_questions}</p>
                <div className="modal_correct_wrong">
                  <div className="modal_correct modal_correct_mock_quiz">
                    <p className="modal_correct_name">Correct</p>
                    <p className="modal_correct_name">{exam?.exam_result?.correct_answers}</p>
                  </div>
                  <div className="modal_wrong">
                    <p className="modal_wrong_wrong">Wrong</p>
                    <p className="modal_wrong_wrong">{exam?.exam_result?.incorrect_answers}</p>
                  </div>
                </div>
                <div className="modal_goTo_butt modal_skipped_questions">
                  <button>Skipped Questions: <span>{exam?.exam_result?.skipped_answers}</span></button>
                </div>
              </div>
              <div className="result_modal_second_second_column">
                <img
                  src="images/checklist.svg"
                  alt=""
                  className="checklist_image"
                />
              </div>
            </div>
            {/* <div className="col-md-12 mb-2">
                                <h3 className="text-black-head-pay fw-semibold examtest-resultm">Your exam result will be out on <span className="examtest-moment">{moment(resultModalDate).format('DD-MM-YYYY')}</span>.</h3>
                            </div> */}
          </Modal.Body>
        </Modal>

      </div>
    </>
  );
};
export default ExamTest;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css"
import { GetLocalStorage, RemoveUsersession } from "../../utils/utils";
import { toast } from "react-toastify";
import { HiBars3 } from "react-icons/hi2";
import { RxCross2 } from 'react-icons/rx';
import { Button, Modal } from "react-bootstrap";

const Header = ({ path, SchoolDetails, setaniModal }) => {
    const navigate = useNavigate();
    const urlPath = window.location.pathname;
    const [toggle, setToggle] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [showDelete, setshowDelete] = useState(false);

    const handleLogout = () => {
        RemoveUsersession();
        localStorage.setItem('logoutEvent', Date.now().toString());
        navigate('/')
        setTimeout(() => {
            toast.success("Logout Successfully");
        }, 200);
    }

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'logoutEvent') {
                handleLogout();
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        let LoginToken = GetLocalStorage("LoginToken");
        let userProfile = GetLocalStorage("UserProfile");
        if (userProfile === '' || userProfile === null || LoginToken === '' || LoginToken === null) {
            navigate('/')
        } else {
            let data = JSON.parse(userProfile)
            if (data?.is_otp_verified !== 'DN') {
                if (urlPath.toLowerCase() !== '/otp') {
                    navigate("/otp", { state: { source: "login", path: path } });
                }
            }
            else if (data?.is_school_filled !== 'DN') {
                if (urlPath.toLowerCase() !== '/schooldetails') {
                    navigate("/SchoolDetails", { state: path });
                }
            }
            else if (data?.is_payment_done !== 'DN') {
                if (urlPath.toLowerCase() !== '/schooldetails') {
                    navigate("/SchoolDetails", { state: { source: "payment", path: path } });
                }
            }
            // else if (data?.is_payment_done === 'DN') {
            //     setaniModal(true);
            // if (urlPath.toLowerCase() === '/schooldetails') {
            //     navigate("/dashboard")
            // }
            // }
        }
    }, []);


    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (width < 1200) {
            navigate("/mobileVersion")
        }
    }, [width])

    const checkIsDashboard = window?.location?.pathname;

    const hideDelete=()=>{setshowDelete(false)}

    return (
        <>
            <div id="dashboardHeader">
                <div className="container">
                    <div className="row dashheader">
                        <div><img src="images/dashboard/dash-logo.webp" alt="" className="dash-header-image" onClick={() => navigate("/dashboard", { state: path })} /></div>
                        <div className="toggle-btn-three toggle-dash-header" onClick={() => setToggle(!toggle)}>
                            {
                                toggle ? <RxCross2 /> : <HiBars3 />
                            }
                        </div>
                        <div className={`dash-header-links ${toggle ? 'nav-links-show' : 'nav-links-not'}`}>{
                            !SchoolDetails ? <>
                                <span onClick={() => { navigate("/dashboard", { state: path }) }} className={checkIsDashboard === "/dashboard" ? "dash-active-link dash-header-btn" : "dash-header-btn"}>Dashboard</span>
                                <span onClick={() => { navigate("/profile", { state: path }) }} className={checkIsDashboard === "/profile" ? "dash-active-link dash-header-btn" : "dash-header-btn"}>Profile</span>
                                <button className="dash-logout" onClick={()=>setshowDelete(true)}>Logout</button>
                            </> : <button className="dash-logout" onClick={()=>setshowDelete(true)}>Logout</button>
                        }

                        </div>
                    </div>
                </div>
            </div>

            <Modal className="custom_new_exam_modal_main" show={showDelete} onHide={hideDelete}>
                <Modal.Body className="payForm">
                    <div className="row payment ">
                        <div className="col-md-12 pb-3">
                            <div className="headLine custom_headline_1 custom_modal_headline_new">Are you sure you want to Logout? </div>
                            <div className="underLine custom_underline_1"></div>
                        </div>
                        <Modal.Footer className="pb-0">
                            <Button
                                onClick={handleLogout}
                                className="fl-btn st-6 custom_new_exam_modal custom_logout_button_1"
                                id="dashboard-butt"
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => setshowDelete(false)}
                                className="fl-btn st-6 custom_new_exam_modal custom_logout_button_1"
                                id="dashboard-butt"
                            >
                                No
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Header;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import './headerthree.css';
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Common/images/logo/quiz-logo.webp";
import { HiBars3 } from "react-icons/hi2";
import { RxCross2 } from 'react-icons/rx'
const HeaderThree = () => {
    const path = window.location.pathname;
    const navigate = useNavigate()
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const [toggle, setToggle] = useState(false);
    const headerRef = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    
    const navigatetoSignup = () => {
        if (path?.endsWith("/5-8")) {
            navigate("/signup", { state: "5-8" })
        } else if (path?.endsWith("/9-10")) {
            navigate("/signup", { state: "9-10" })
        } else if (path?.endsWith("/11-12")) {
            navigate("/signup", { state: "11-12" })
        } else {
            navigate("/signup", { state: "5-8" })
        }
    }
    
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (width < 1200) {
            navigate("/mobileVersion")
        }
    }, [width])

    return (
        <>
            <div className="header_one_container">
                <div className="header_one_top">
                    <div className="container">
                        <div className="row">
                            <a className="header_one_auto" href="tel:9893472716"><i className="fa fa-mobile-screen-button"></i>9893472716</a>
                            <a className="header_one_auto info" href="mailto:info@scholarmonksquiz.com"><i className="fa-regular fa-envelope"></i>info@scholarmonksquiz.com</a>
                        </div>
                    </div>
                </div>
                <div className={`container header_one_bottom ${sticky.isSticky ? 'sticky' : ''}`} ref={headerRef}>
                    <div className="row">
                        <div className="col-md-6 resp_header_logo"><img src={logo} alt="logo" /></div>
                        <div className="toggle-btn-three" onClick={() => setToggle(!toggle)}>
                            {
                                toggle ? <RxCross2 /> : <HiBars3 />
                            }
                        </div>
                        <div className={`col-md-6 nav-links ${toggle ? 'nav-links-show' : 'nav-links-not'}`}>
                            <a href="https://scholarmonksquiz.com/11-12/">HOME</a>
                            <a href="https://scholarmonksquiz.com/11-12/about.php">ABOUT US</a>
                            <a href="https://scholarmonksquiz.com/11-12/contact.php">CONTACT</a>
                            <Link to="/11-12">LOGIN</Link>
                            <div className="sign_up" onClick={navigatetoSignup}>SIGN UP</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderThree;

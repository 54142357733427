/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import './headerone.css';
import logo from "../../Common/images/logo/quiz-logo.webp";
import { Link, useNavigate } from "react-router-dom";

const HeaderOne = () => {
    const path = window.location.pathname;
    const navigate = useNavigate()
    const [toogle, setToogle] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);

    const handleToogle = () => {
        toogle ? setToogle(false) : setToogle(true)
    }
    const navigatetoSignup = () => {
        if (path?.endsWith("/5-8")) {
            navigate("/signup", { state: "5-8" })
        } else if (path === "/9-10") {
            navigate("/signup", { state: "9-10" })
        } else if (path?.endsWith("/11-12")) {
            navigate("/signup", { state: "11-12" })
        } else {
            navigate("/signup", { state: "5-8" })
        }
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (width < 1200) {
            navigate("/mobileVersion")
        }
    }, [width])

    return (
        <>
            <div className="header_three">
                <div className="container">
                    <div className="row header-one-row-cont">
                        <div className="header-three_logo">
                            {/* <img src={logo} alt="logo" className="header-three_logo" /> */}
                            <img src="images/dashboard/dash-logo.webp"
                                // onError={(e) => { e.target.src = "images/dashboard/dash-logo.webp" }}
                                alt="logo" className="header-three_logo" />
                        </div>
                        <div className="header-one-mail-phone">
                            <button onClick={handleToogle}><img src={toogle ? 'images/close.webp' : 'images/menu.webp'} alt="menu" /></button>
                            <div className="header_three_social header-email">
                                <img src='images/email.webp'
                                    // onError={(e) => { e.target.src = "images/email.webp" }}
                                    alt="email" />
                                <div className="header_three_inner_social">
                                    <div>Email</div>
                                    <a href="mailto:info@scholarmonksquiz.com">info@scholarmonksquiz.com</a>
                                </div>
                            </div>
                            <div className="header_three_social">
                                <img src='images/call.webp'
                                    // onError={(e) => { e.target.src = "images/call.webp" }}
                                    alt="hotline" />
                                <div className="header_three_inner_social">
                                    <div>Hotline</div>
                                    <a href="tel:9893472716">9893472716</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container header_three_bottom">
                    <div className={toogle ? 'row heder-row' : 'row heder-row hidden-heder-row'}>
                        <a href="https://scholarmonksquiz.com/5-8/">HOME</a>
                        <a href="https://scholarmonksquiz.com/5-8/about.php">ABOUT</a>
                        <a href="https://scholarmonksquiz.com/5-8/contact.php">CONTACT</a>
                        <Link to="/5-8">LOGIN</Link>
                        <p className="sign-up-p" onClick={navigatetoSignup}>SIGNUP</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default HeaderOne;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import loginImage5_8 from "../../Components/Common/images/LoginImages/5-8.webp";
import loginImage9_10 from "../../Components/Common/images/LoginImages/9-10.webp";
import loginImage11_12 from "../../Components/Common/images/LoginImages/11-12.webp";
import axios from "axios";
import { ApiUrl, GetLocalStorage, SetLocalStorage, userPhoneNo } from "../../Components/utils/utils";
import { userData } from "../../Components/utils/utils";
import HeaderOne from "../../Components/HeaderOne/Header/headerone";
import FooterOne from "../../Components/HeaderOne/Footer/footerone";
import FooterTwo from "../../Components/HeaderTwo/Footer/footertwo";
import FooterThree from "../../Components/HeaderThree/footer/footerthree";
import HeaderTwo from "../../Components/HeaderTwo/Header/headertwo";
import HeaderThree from "../../Components/HeaderThree/header/headerthree";
import moment from 'moment';
import Loader from "../../Components/Loader/Loader"
// import Header from "../Header/Header";
// import Footer from "../Footer/Footer";
const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let path = location?.state;
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [DOB, setDOB] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [OpenPassword, setOpenPassword] = useState(false);
  const [OpenCPassword, setOpenCPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const Eyetoggle = () => {
    setOpenPassword(!OpenPassword);
  };
  const Eyetoggle2 = () => {
    setOpenCPassword(!OpenCPassword);
  };
  useEffect(() => {
    path = path !== undefined ? path : ""
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (e.target.type === "number" && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const validatefields = () => {
    if (!fullName || fullName.trim() === "") {
      toast.error("Please enter full name");
      return false;
    }
    if (fullName?.length > 60) {
      toast.error("Name should be less or equal to 60 characters"); return
    }
    if (!fullName.match(/^[a-zA-Z ]*$/)) {
      toast.error("Name should contain only alphabets and spaces"); return
    }
    if (!email || email?.trim() === '') { toast.error("Please enter email"); return }
    if (email.trim() === "") {
      toast.error("Please enter your email");
      return false;
    }
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      // if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      toast.error("email invalid");
      return false;
    }
    if (email?.length > 70) {
      toast.error("Email should be less or equal to 70 characters"); return
    }
    if (mobileNumber.trim() === "") {
      toast.error("Please enter your mobile number");
      return false;
    }
    if (mobileNumber.length < 10) {
      toast.error("Please enter your correct mobile number");
      return false;
    }
    if (DOB.trim() === "") {
      toast.error("Please enter your DOB");
      return false;
    }
    const currentDate = new Date();
    var minAllowedDate = new Date(Date.now() - 3600 * 1000 * 24);
    minAllowedDate.setFullYear(currentDate.getFullYear() - 8);
    const dob = moment(DOB).format('MM/DD/YYYY')
    const valid = moment(minAllowedDate).format('MM/DD/YYYY')
    if (new Date(dob) > new Date(valid)) {
      toast.error("You must be at least 8 years old");
      return false
    }
    if (password.trim() === "") {
      toast.error("Please enter password");
      return false;
    }
    if (!password.match(/[a-z]/) || !password.match(/[A-Z]/) || !password.match(/[0-9]/) ||
      !password.match(/[!@#$%^&*]/) || password?.length < 8) {
      toast.error("Password must have minimum 8 characters and contain uppercase, lowercase, number & special character");
      return false;
    }
    if (confirmPassword.trim() === "") {
      toast.error("Please enter your password again");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Password should match ");
      return false;
    }
    return true;
  };
  const handleSignup = (e) => {
    e.preventDefault();
    if (!validatefields()) return;
    const data = {};
    data.name = fullName;
    data.email = email;
    data.mobile_no = mobileNumber;
    data.dob = DOB;
    data.password = password;
    setloading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/login.php?request=student_signup`,
    };
    axios(option)
      .then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          userPhoneNo(e?.data?.result_data?.mobile_no);
          userData(e?.data?.result_data);
          SetLocalStorage("UserData", JSON.stringify(e.data.result_data));
          navigate("/otp", { state: { source: "register", path: path } });
          setTimeout(() => {
            toast.success("Otp Sent");
          }, 20);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const navigatetoLogin = () => {
    if (path === "5-8") {
      navigate("/5-8", { state: "/5-8" })
    } else if (path === "9-10") {
      navigate("/9-10", { state: "/9-10" })
    } else if (path === "11-12") {
      navigate("/11-12", { state: "/11-12" })
    } else {
      navigate("/5-8", { state: "/5-8" })
    }
  }

  useEffect(() => {
    const pathname = window.location.pathname?.toLowerCase();
    const UserProfile = JSON.parse(GetLocalStorage("UserProfile"));
    if (UserProfile && pathname?.endsWith("/signup")) {
      UserProfile?.is_payment_done === "DN" ? navigate("/dashboard") : navigate("/SchoolDetails");
      setTimeout(() => {
        toast.error("User Already Logged In")
      }, 50);
    }
  }, [])

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      {
        path === "5-8" ? <HeaderOne /> : path === "9-10" ? <HeaderTwo /> : path === "11-12" ? <HeaderThree /> : <HeaderOne />
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        draggable
        bodyClassName="fs-4"
        theme="colored"
      />

      <section className="tf-section tf-message signup-login">
        <div className="container-fluid main-container-pad">
          <div className="row">
            <div className="col-md-12">
              <div className="form-message section-signup-cstm signup-cstm-2">
                <div className="col-md-6" id="image-login">
                  <section className="login-image-section">
                    <img src={path?.endsWith("5-8") ? loginImage5_8 : path?.endsWith("9-10") ? loginImage9_10 : loginImage11_12} alt="" className="login-image-main" />
                  </section>
                </div>
                <div className="col-md-6" id="mobile-login">
                  <div className="login-form-div-1 main-section-signup">
                    <h2 className="heading heading-create-acc">
                      Create an Account
                    </h2>
                    <form
                      action="https://themesflat.co/kinco/contact/contact-process4.php"
                      method="post"
                      id="commentform"
                      className="comment-form"
                    >
                      <div className="fx flex-wrap">
                        <div className="input-field-div">
                          <fieldset className="name">
                            <input
                              type="text"
                              placeholder="Full Name Here"
                              required
                              name="name"
                              className="name input_border"
                              id="name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="email">
                            <input
                              type="email"
                              placeholder="Email Address"
                              required
                              name="mail"
                              className="mail input_border"
                              id="mail"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </fieldset>
                        </div>
                        <div className="input-field-div">
                          <fieldset className="phone">
                            <input
                              type="number"
                              placeholder="Phone Number"
                              onKeyPress={inputHandler}
                              name="number"
                              className="number input_border"
                              id="number"
                              min="0"
                              value={mobileNumber}
                              maxLength="10"
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="select-wrap" role="group">
                            <div className="select">
                              <input
                                type="date"
                                placeholder="D.O.B"
                                required
                                name="number"
                                className="number input_border"
                                id="number3"
                                value={DOB}
                                max={new Date().toISOString().split("T")[0]}
                                onChange={(e) => setDOB(e.target.value)}
                              />
                            </div>
                          </fieldset>
                        </div>
                        <fieldset className="message">
                          <input
                            type={OpenPassword === false ? "password" : "text"}
                            placeholder="Password"
                            value={password}
                            required
                            name="password"
                            className="password-1 input_border"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="off"
                          />{" "}
                          <span className="eye">
                            {OpenPassword === false ? (
                              <AiOutlineEyeInvisible onClick={Eyetoggle} />
                            ) : (
                              <AiOutlineEye onClick={Eyetoggle} />
                            )}
                          </span>
                        </fieldset>
                        <fieldset className="message">
                          <input
                            type={OpenCPassword === false ? "password" : "text"}
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            required
                            name="password"
                            className="password-1 input_border"
                            id="password2"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            autoComplete="off"
                          />{" "}
                          <span className="eye">
                            {OpenCPassword === false ? (
                              <AiOutlineEyeInvisible onClick={Eyetoggle2} />
                            ) : (
                              <AiOutlineEye onClick={Eyetoggle2} />
                            )}
                          </span>
                        </fieldset>

                        <div className="wrap-btn btn-signup-main">
                          <button
                            type="submit"
                            className="fl-btn st-6"
                            onClick={handleSignup}
                          >
                            <span className="inner">Sign Up</span>
                          </button>
                        </div>
                        <p className="butt-p">
                          Have an account?&nbsp;
                          <span className="link-login" onClick={navigatetoLogin}>
                            Login
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        path === "5-8" ? <FooterOne /> : path === "9-10" ? <FooterTwo /> : path === "11-12" ? <FooterThree /> : <FooterOne />
      }
    </>
  );
};
export default Signup;

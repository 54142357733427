/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./SchoolDetails.css";
import axios from "axios";
import {
  ApiUrl,
  GetLocalStorage,
  RemoveUsersession,
  SetLocalStorage,
} from "../../Components/utils/utils";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../Components/DashboardNew/Header/Header";
import Footer from "../../Components/DashboardNew/Footer/Footer";
import Loader from "../../Components/Loader/Loader";
// import Header from "../../Components/Header/Header";
// import Footer from "../../Components/Footer/Footer";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import Pay from "../pay/pay";
import Animation from "../../Components/Animation/Animation";
import Select from "react-select";
const SchoolDetails = ({ path, source }) => {
  let isAlert = false;
  const path1 = window.location.search;
  const navigate = useNavigate();
  const location = useLocation();
  // const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
  const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
  const [stateId, setStateId] = useState("");
  const [statedata, setStatedata] = useState([]);
  const [schoolId, setSchoolId] = useState("");
  const [schooldata, setSchoolData] = useState([]);
  const [classId, setClassid] = useState("");
  const [classdata, setClassData] = useState([]);
  const [cityId, setcityId] = useState("");
  const [city, setCity] = useState([]);
  const [loading, setloading] = useState(false);
  const [DateAmount, setDateAmount] = useState(false);
  const [examId, setExamId] = useState("");
  const [examData, setExamData] = useState([]);
  const [show, setShow] = useState(false);
  const [orderData, setOrderData] = useState();
  const [examDataFind, setExamDataFind] = useState();
  const [aniModal, setaniModal] = useState(false);
  const [h2, seth2] = useState("Great you are now all signed up!");
  const html = document.querySelector("html");
  const closePay = () => { html.style.overflow = "unset"; setShow(false); }
  const showPay = (item) => {
    setShow(true);
    html.style.overflow = "hidden";
    setOrderData(item);
  };

  const pathName = `${window.location.pathname?.toLowerCase()}`;

  useEffect(() => {
    setcityId("");
    setSchoolId("");
    setClassid("");
  }, [stateId]);

  useEffect(() => {
    setSchoolId("");
    setClassid("");
  }, [cityId]);

  useEffect(() => {
    setClassid("");
  }, [schoolId]);

  useEffect(() => {
    if (examId) {
      const exams = examData?.find((exam) => exam?.exam_id === Number(examId));
      setExamDataFind(exams);
    }
  }, [examId]);

  const initData = () => {
    let LoginToken = GetLocalStorage("LoginToken");
    let userProfile = GetLocalStorage("UserProfile");
    if (userProfile === undefined || LoginToken === undefined || LoginToken === "") {
      navigate("/");
    } else {
      let data = JSON.parse(userProfile);
      if (data?.is_school_filled === "DN") {
        getExamsDropList();
        setDateAmount(true);
      } else {
        Poststatedata();
      }
    }
  };

  const GetProfile = () => {
    const data = { x: "1" };
    setloading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/student.php?request=get_profile`,
    };
    axios(option)
      .then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          navigate("/SchoolDetails", { state: path });
          setTimeout(() => {
            toast.error("Please fill your details first");
          }, 50);
        }
        if (e?.data?.result_code === "OK") {
          SetLocalStorage("UserProfile", JSON.stringify(e.data.result_data));
          if (e.data.result_data.is_payment_done === "DN") {
            /////////////////////////
            setaniModal(true);
            // navigate("/Dashboard")
          } else {
            navigate("/SchoolDetails");
          }
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const Poststatedata = () => {
    setloading(true);
    const data = {};
    data.state_name = "";
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=states_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          setStatedata(e?.data?.result_data);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (path1 && path1?.includes("status=failed")) {
      toast.error("Payment Failed.");
      initData();
    } else if (path1 && path1.includes("status=done")) {
      if (!isAlert) {
        toast.success("Payment Done.");
        isAlert = true;
        GetProfile();
      }
    } else {
      initData();
    }
  }, []);

  const Postcitydata = () => {
    setloading(true);
    const data = {};
    data.city_name = ""; //cityname;
    data.state_id = stateId?.value;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=city_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          setCity(e?.data?.result_data);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (stateId) {
      Postcitydata();
    }
  }, [stateId]);

  const Postschooldata = () => {
    setloading(true);
    const data = {};
    data.city_id = cityId?.value;
    data.state_id = stateId?.value;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=school_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          setSchoolData(e?.data?.result_data);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (cityId) {
      Postschooldata();
    }
  }, [cityId]);

  const Postclassdata = () => {
    setloading(true);
    const data = {};
    data.school_id = schoolId?.value;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=class_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          setClassData(e?.data?.result_data);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (schoolId) {
      Postclassdata();
    }
  }, [schoolId]);

  const Postschooldetails = () => {
    setloading(true);
    const data = {};
    data.state_id = parseInt(stateId?.value);
    data.city_id = parseInt(cityId?.value);
    data.school_id = parseInt(schoolId?.value);
    data.class_id = parseInt(classId?.value);
    setloading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/signup.php?request=save_school_details`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          // let userProfile = localStorage.getItem("UserProfile") || undefined;
          let userProfile = GetLocalStorage("UserProfile");
          if (userProfile) {
            const data = JSON.parse(userProfile);
            data.is_school_filled = "DN";
            SetLocalStorage("UserProfile", JSON.stringify(data));
          }
          getExamsDropList();
          setDateAmount(true);
          setTimeout(() => {
            toast.success(e?.data?.message);
          }, 50);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  const getExamsDropList = () => {
    setloading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "aplication/json",
        Usertoken: LoginToken,
      },
      url: `${ApiUrl()}/start_quiz.php?request=get_exam_drop_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          setExamData(e?.data?.result_data?.data);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  // useEffect(() => {
  //   getExamsDropList();
  // }, [])

  //----------------post Exam Date--------------//
  // const Examdatedetail = () => {
  //   const data = {};
  //   data.exam_date = formattedDate;
  //   const option = {
  //     method: "POST",
  //     headers: {
  //       "access-control-allow-origin": "*",
  //       "content-type": "application/json",
  //       Usertoken: LoginToken,
  //     },
  //     data: data,
  //     url: `${ApiUrl()}/signup.php?request=save_exam_date`,
  //   };
  //   axios(option)
  //     ?.then((e) => {
  //       if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
  //         toast.error(e?.data?.message);
  //       }
  //       if (e?.data?.result_code === "OK") {
  //         navigate("/dashboard");
  //         setTimeout(() => {
  //           toast.success(e?.data?.message);
  //         }, 50);
  //       }
  //     })
  //     ?.catch((err) => {setloading(false)});
  // };

  const validateSubmitAmount = () => {
    if (!examId || examId === "") {
      toast.error("Please Select Exam Date and Amount");
      return false;
    }
    return true;
  };

  const handleSubmitAmount = (e) => {
    e?.preventDefault();
    if (!validateSubmitAmount()) return;
    const data = {};
    data.exam_id = examDataFind?.exam_id;
    setloading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/signup.php?request=initialize_exam_payment`,
    };
    axios(option)
      ?.then((e) => {
        if (e?.data?.result_data) {
          showPay(e?.data?.result_data);
        }
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
      })
      ?.catch((err) => {
        setloading(false);
      });
  };

  const validateField = () => {
    if (!stateId) {
      toast.error("Please select state");
      return false;
    }
    if (stateId === "select-state") {
      toast.error("Please select state");
      return false;
    }
    if (!cityId) {
      toast.error("Please select city");
      return false;
    }
    if (cityId === "select-city") {
      toast.error("Please select city");
      return false;
    }
    if (!schoolId) {
      toast.error("Please select school");
      return false;
    }
    if (schoolId === "select-school") {
      toast.error("Please select school");
      return false;
    }
    if (!classId) {
      toast.error("Please select class");
      return false;
    }
    if (classId === "select-class") {
      toast.error("Please select class");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (!validateField()) return;
    Postschooldetails();
  };

  useEffect(() => {
    const path1 = window.location.search;
    if (path1 && path1.includes("status=done")) {
      if (!aniModal) {
        setaniModal(true);
      }
    }
  }, []);

  useEffect(() => {
    if (aniModal) {
      setTimeout(() => {
        setaniModal(false);
        RemoveUsersession();
        navigate("/");
      }, 15000);
    }
  }, [aniModal]);

  // -----ReactSelect state option------
  const StateOptions = statedata?.map((state) => {
    return { value: state?.state_id, label: state?.state_name };
  });
  const CityOptions = city?.map((city) => {
    return { value: city?.city_id, label: city?.city_name };
  });
  const SchoolList = schooldata?.map((school) => {
    return { value: school?.school_id, label: school?.school_name };
  });
  const ClassList = classdata?.map((item) => {
    return { value: item?.class_id, label: item?.class_name };
  });



  return (
    <>
      {aniModal ? (
        <Animation aniModal={aniModal} setaniModal={setaniModal} h2={h2} />
      ) : null}

      {loading ? <Loader /> : null}
      <div className="main-sch-det-cont">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          draggable
          bodyClassName="fs-4"
          theme="colored"
        />
        <Header path={location?.state} SchoolDetails={true} />

        <section className="tf-section tf-message main-section-school">
          <div className="container-fluid main-container-pad">
            <div className="heading-school">
              <h1 className="heading heading-create-acc">School Details</h1>
            </div>
            <div className="row">
              <div className="col-md-12 form-div-main">
                <div className="form-message form-schoold-detail">
                  <div className="col-md-12">
                    <div className="dropdowninput-form-div-1">
                      <form className="comment-form">
                        {!DateAmount ? (
                          <>
                            <div className="schooldetailsflex">
                              <div className="schooldetailsflex--1">
                                <label className="phone-head">
                                  Select State
                                </label>
                                <Select
                                  options={StateOptions}
                                  onChange={(val) => { setStateId(val); setSchoolData([]); setClassData([]) }}
                                  value={stateId}
                                  placeholder="Select State"
                                  classNamePrefix="Select_school_detail"
                                />

                                {/* <select value={stateId} onChange={(e) => setStateId(e.target.value)} className="input_border">
                                    <option defauitem.state_nameltValue disabled value="">Select State</option>
                                    {
                                      statedata.map((item, i) => (
                                        <option key={i} value={item.state_id}>{" "}{}</option>
                                      ))
                                    }                                  </select> */}
                              </div>
                              <div className="schooldetailsflex--1">
                                <label className="phone-head">
                                  Select City
                                </label>
                                <Select
                                  options={CityOptions}
                                  onChange={(val) => { setcityId(val); setClassData([]) }}
                                  value={cityId}
                                  placeholder="Select City"
                                  classNamePrefix="Select_school_detail"
                                />
                                {/* <select
                                  value={cityId}
                                  onChange={(e) => setcityId(e.target.value)}
                                  className="input_border"
                                >
                                  <option defaultValue disabled value="">
                                    Select City
                                  </option>

                                  {city?.map((item, i) => (
                                    <option key={i} value={item.city_id}>
                                      {item.city_name}
                                    </option>
                                  ))}
                                </select> */}
                              </div>
                            </div>
                            <div className="schooldetailsflex">
                              <div className="schooldetailsflex--1 mt-4">
                                <label className="phone-head">
                                  Select School
                                </label>
                                <Select
                                  options={SchoolList}
                                  onChange={(val) => {
                                    setSchoolId(val);
                                    setClassid("");
                                  }}
                                  value={schoolId}
                                  placeholder="Select School"
                                  classNamePrefix="Select_school_detail"
                                />
                              </div>
                              <div className="schooldetailsflex--1  mt-4">
                                <label className="phone-head">
                                  Select Class
                                </label>
                                <Select
                                  onChange={(val) => {
                                    setClassid(val);
                                  }}
                                  options={ClassList}
                                  value={classId}
                                  placeholder="Select Class"
                                  classNamePrefix="Select_school_detail"
                                />
                              </div>
                            </div>
                            <div className="dropdown-wrap-btn">
                              <button
                                type="submit"
                                className="fl-btn st-d"
                                onClick={handleSubmit}
                              >
                                <span className="inner">Submit & Continue</span>
                              </button>

                              { }
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="schooldetailsflex">
                              <div className="schooldetailsflex--1">
                                <label className="phone-head">
                                  {" "}
                                  Select Exam Date{" "}
                                </label>
                                <select
                                  className="input_border"
                                  value={examId}
                                  onChange={(e) => {
                                    setExamId(e?.target?.value);
                                  }}
                                >
                                  <option defaultValue disabled value="">
                                    Select Exam Date{" "}
                                  </option>
                                  {examData?.map((item, i) => (
                                    <option
                                      key={i}
                                      value={item?.exam_id}
                                    >{`${item?.exam_month} `}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {examDataFind ? (
                              <>
                                <div className="mt-4">
                                  <label className="phone-head-school">
                                    {" "}
                                    Exam Title :{" "}
                                    <span>{examDataFind?.title}</span>
                                  </label>
                                  <label className="phone-head-school">
                                    {" "}
                                    Exam Description :{" "}
                                    <span>{examDataFind?.title || "-"}</span>
                                  </label>
                                  <label className="phone-head-school">
                                    {" "}
                                    Exam Fee :{" "}
                                    <span>
                                      {" "}
                                      Rs. {examDataFind?.exam_payment}
                                    </span>
                                  </label>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="dropdown-wrap-btn">
                              <button
                                className="fl-btn st-d"
                                onClick={(e) => handleSubmitAmount(e)}
                              >
                                <span className="inner">Submit</span>
                              </button>
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal show={show} onHide={closePay} backdrop="static" keyboard={false}>
          <Pay setShow={setShow} orderData={orderData} examDataFind={examDataFind}
            failedUrl={pathName?.endsWith("/schooldetails") ? "signup" : "home"} />
        </Modal>
        <Footer />
      </div>
    </>
  );
};
export default SchoolDetails;

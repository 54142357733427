/* eslint-disable react-hooks/exhaustive-deps */
import "../../pages/Quiz/Css/animation.css";
import "../../pages/Quiz/Css/responsive.css";
import "../../pages/Quiz/Css/result_style.css";
import "../../pages/Quiz/Css/Quiz.css";
import "../../pages/Quiz/Css/Bootstrap/bootstrap.min.css";
import React, { Fragment, useEffect, useState } from "react";
import { ApiUrl, GetLocalStorage, RemoveLocalStorage, SetLocalStorage } from "../../Components/utils/utils";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import untitle from "../../Components/Common/images/untitle.webp";
import { ImNotification } from "react-icons/im";
// import backImage from "../../Components/Common/images/quiz/bh-clip.webp";
import images from "../../Components/Common/images/math.webp";
import logoImage from '../../Components/Common/images/logoImage.webp'
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";


const Exam = () => {
  const [question, setQuestion] = useState();
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [answerSelected, setAnswerSelected] = useState();
  const [quesId, setQuesId] = useState();
  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [userAnswers, setUserAnswers] = useState([]);
  const [hitApi, setHitApi] = useState(false);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
  const result_id = GetLocalStorage("resultId");
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [resultModalDate, setresultModalDate] = useState();
  const [imageModal, setImageModal] = useState();
  const handleShowImageModal = (item) => setImageModal(item);
  const handleCloseImageModal = () => setImageModal("");
  useEffect(() => {
    setresultModalDate(GetLocalStorage("result_date"))
  }, [])
  // setresultModalDate(item?.exam_result_date);
  const handleClose3 = () => { setShow3(false); setresultModalDate() }

  const Handlesub = () => {
    window.close();
    RemoveLocalStorage('result_date');
    RemoveLocalStorage('Exam');
    window.opener.location.href = "dashboard";
  };
  // --------modal open-------
  // const [resultresponsedata, setResultresponsedata] = useState("");
  useEffect(() => {
    const resultData = JSON.parse(GetLocalStorage("Exam"));
    setQuestion(resultData);
  }, []);
  const data = question?.data;
  // -----sending cureent time to api---------//
  const end_time = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now
      .getDate()
      .toString()
      .padStart(2, "0")} ${hours}:${minutes}:${seconds}`;
  // Quiz Timer----------------//
  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((secondsLeft) => secondsLeft - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  // Right Click Disable---------/
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  // ---------Disable Radio Button On Timeout-------//
  if (secondsLeft === 0) {
    const radioButtons = document.getElementsByName("ques");
    for (let i = 0; i < radioButtons?.length; i++) {
      radioButtons[i].disabled = true;
    }
  }
  const totalQuestions = data?.length;
  const totalQuestionNumber = parseInt(GetLocalStorage("totalQuestions"));
  const [rem, setrem] = useState(11);
  const [count, setCount] = useState(1)
  let defaultarr = [];
  for (let i = 1; i <= totalQuestionNumber; i++) {
    defaultarr.push(i)
  }
  const firstTenItems = defaultarr?.slice(0, 10)
  const [newNumberArr, setNewNumberArr] = useState(firstTenItems)
  const [newShowingArr, setNewShowingArr] = useState([])
  useEffect(() => {
    if (count <= totalQuestionNumber) {
      if (count % rem === 0) {
        setrem(rem + 10)
        const data = defaultarr.slice(count - 1, count + 9);
        setNewNumberArr(data)
        setNewShowingArr([...newShowingArr, count])
      } else {
        setNewShowingArr([...newShowingArr, count])
      }
    }
  }, [count])

  // ----------setting questionId to local storage--------//
  // const not_Selected_questionId = SetLocalStorage("examQuesId",data?.question_id?.toString());
  const get_question_id = GetLocalStorage("examQuesId");
  // -----------image url-------//
  // const imageUrl = `${ImageUrl()}${data?.question_image}`;
  // -----------reset radio button----------//
  const resetRadioButtons = () => {
    const radioButtons = document.getElementsByName("ques");
    for (let i = 0; i < radioButtons?.length; i++) {
      radioButtons[i].disabled = false;
      radioButtons[i].checked = false;
    }
    setAnswerSelected("");
  };
  //------------next question-------------//
  const handleNext = (e, questionId, item) => {
    if (!(item === "skip") && !(item === "submit")) {
      if (!answerSelected) {
        toast.error("Select an option to proceed"); return;
      }
    }
    //////////////////////
    if (item === "submit" && lastIndex === totalQuestionNumber) {
      if (!answerSelected) {
        toast.error("Select an option to Submit"); return;
      }
    }
    ///////////////////
    setCount(count + 1)
    setUserAnswers([
      ...userAnswers,
      {
        question_id: quesId || parseInt(get_question_id) || questionId,
        answer_given: item === "skip" ? 0 : parseInt(answerSelected) || 0,
      },
    ]);
    if (currentPage === totalQuestions) {
      setHitApi(true);
    }

    setCurrentPage(currentPage + 1);
    if (lastIndex === data?.length) {
      setCurrentPage(currentPage);
    }
    setSecondsLeft(60);
    resetRadioButtons();
  };

  //  Api Post Answer----------
  const handleAnswerSubmit = (e) => {
    e?.preventDefault();
    const data = userAnswers;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: { answers: data, result_id, end_time },
      url: `${ApiUrl()}/submit_quiz.php?request=submit_answer_exam`,
    };
    axios(option)
      .then((e) => {
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
        }
        if (e?.data?.result_code === "OK") {
          // setResultresponsedata(e?.data?.result_data);
          setShow2(false);
          setShow3(true);
          // Handlesub();
          RemoveLocalStorage("totalQuestions");
        }
      })
      .catch((err) => { });
  };

  const StudentRank = () => {
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: {},
      url: `${ApiUrl()}/start_quiz.php?request=get_student_rank`,
    };
    axios(option)
      .then((e) => {
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
        }
        if (e?.data?.result_code === "OK") {
          SetLocalStorage("studentRank", JSON.stringify(e?.data?.result_data?.rank));
        }
      })
      .catch((err) => { });
  };
  // ---------- calling api---------------//
  useEffect(() => {
    if (hitApi) {
      handleAnswerSubmit();
      StudentRank();
      setSecondsLeft(0);
    }
  }, [hitApi]);
  //----------- radio button value-------------//
  const MockApiHandler = (e, questionId) => {
    setAnswerSelected(e.target.value);
    setQuesId(questionId);
  };
  useEffect(() => {
    if (secondsLeft === 0) {
      handleCloseImageModal()
    }
  }, [secondsLeft])

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        draggable
        bodyClassName="fs-4"
        theme="colored"
      />
      <main className="overflow-hidden">
        <div className="row">
          {data?.slice(firstIndex, lastIndex).map((item, index) => {
            const questionId = item.question_id;
            const questionImage = `${question?.image_url}${item?.question_image}`;

            const optionImage1 = `${question?.image_url}${item?.option_one_image}`;
            const optionImage2 = `${question?.image_url}${item?.option_two_image}`;
            const optionImage3 = `${question?.image_url}${item?.option_three_image}`;
            const optionImage4 = `${question?.image_url}${item?.option_four_image}`;
            return (
              <Fragment key={index}>
                <div className="col-md-5 tab-100 order-tab tab-none">
                  <div className="side image-div">
                    <div className="side_image">
                      <div className="border-up"></div>
                      <div className="side_image_div">
                        <div className="newpage1">

                          {
                            newNumberArr?.map((item) => {
                              return (
                                <Fragment key={index}>
                                  <div className={`newpage2 ${newShowingArr?.find(i => i === item) ? "newcolor" : ""}`} style={{ height: `${100 / newNumberArr?.length}%` }}>
                                    <div className={`newpage3 ${newShowingArr?.find(i => i === item) ? "newcolornumber" : ""}`}>{item}</div>
                                  </div >
                                </Fragment>
                              )
                            })
                          }
                        </div>
                        <img src={questionImage || logoImage} alt="side" />
                      </div>
                      <div className="border-down"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 tab-100 display_flex_lg">
                  <div key={index} className="countdown">
                    <h3>
                      <span id="countdown-timer">
                        {secondsLeft > 0 ? (
                          secondsLeft
                        ) : (
                          <>
                            {/* time up modal */}
                            <Modal
                              show={show2}
                              onHide={handleClose2}
                              className="home-model"
                              keyboard={false}
                              backdrop="static"
                            >
                              <Modal.Header className="home-model-header">
                                <Modal.Title className="home-modal-title">
                                  <ImNotification className="icon-modal-show" />
                                </Modal.Title>
                              </Modal.Header>

                              <Modal.Body>
                                <div className="modal-time">
                                  <div className="modal-time-div">
                                    <img src={untitle} alt="" />
                                  </div>

                                  <div className="modal-time-div">
                                    <h1>Times Up</h1>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer className="home-modal-button">
                                {lastIndex === data?.length ? (
                                  <div className="next-prev-butt">
                                    <div className="next-prev">
                                      <button
                                        className="next"
                                        type="button"
                                        id="step1btn"
                                        onClick={(e) =>
                                          handleNext(e, questionId, "skip")
                                        }
                                      >
                                        Ok
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="next-prev-butt">
                                    <div className="next-prev">
                                      <button
                                        className="next"
                                        type="button"
                                        id="step1btn"
                                        onClick={(e) =>
                                          handleNext(e, questionId, "skip")
                                        }
                                      >
                                        Ok
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Modal.Footer>
                            </Modal>
                          </>
                        )}
                      </span>
                      sec
                    </h3>
                  </div>
                  <form method="post" novalidate className="form-head">
                    <div className="show-section wrapper">
                      <section className="steps">
                        <h1 className="quiz-question question_clr_chng">
                          Question:{firstIndex + 1}
                        </h1>
                        <h1 className="quiz-question">{item.question}</h1>
                        <fieldset id={currentPage} className="field-ques">
                          <div>
                            <div className="radio-field bounce-left">
                              <div className="radio-content-div">
                                {
                                  item?.option_one_image ?
                                    <img
                                      className="radio-image-main"
                                      src={optionImage1 || logoImage}
                                      alt=""
                                      onClick={() => { handleShowImageModal(optionImage1) }}
                                    /> : <span className="radio-image-main-x"></span>}
                                <input
                                  type="radio"
                                  value="1"
                                  name="ques"
                                  onClick={(e) => MockApiHandler(e, questionId)}
                                />
                                <label className="op1">
                                  <span className="quiz_span">A:</span>{item.option_one}</label>
                              </div>
                            </div>
                            <div className="radio-field bounce-left delay-100">
                              <div className="radio-content-div">
                                {
                                  item?.option_two_image ?
                                    <img
                                      className="radio-image-main"
                                      src={optionImage2 || images}
                                      alt=""
                                      onClick={() => { handleShowImageModal(optionImage2) }}
                                    /> : <span className="radio-image-main-x"></span>}
                                <input
                                  type="radio"
                                  value="2"
                                  name="ques"
                                  onClick={(e) => MockApiHandler(e, questionId)}
                                />
                                <label className="op2">
                                  <span className="quiz_span">B:</span>{item.option_two}</label>
                              </div>
                            </div>
                            <div className="radio-field bounce-left delay-200">
                              <div className="radio-content-div">
                                {
                                  item?.option_three_image ?
                                    <img
                                      className="radio-image-main"
                                      src={optionImage3 || images}
                                      alt=""
                                      onClick={() => { handleShowImageModal(optionImage3) }}
                                    /> : <span className="radio-image-main-x"></span>}
                                <input
                                  type="radio"
                                  value="3"
                                  name="ques"
                                  onClick={(e) => MockApiHandler(e, questionId)}
                                />
                                <label className="op3">

                                  <span className="quiz_span">C:</span>
                                  {item.option_three}</label>
                              </div>
                            </div>
                            <div className="radio-field bounce-left delay-300">
                              <div className="radio-content-div">
                                {
                                  item?.option_four_image ? <img
                                    className="radio-image-main"
                                    src={optionImage4 || images}
                                    alt=""
                                    onClick={() => { handleShowImageModal(optionImage4) }}
                                  /> : <span className="radio-image-main-x"></span>}
                                <input
                                  type="radio"
                                  value="4"
                                  name="ques"
                                  onClick={(e) => MockApiHandler(e, questionId)}
                                />
                                <label className="op4">
                                  <span className="quiz_span">D:</span>
                                  {item.option_four}</label>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        {lastIndex === data?.length ? (
                          <>
                            <div className="next-prev-butt">
                              <div className="next-prev">
                                <button className="next" type="button" id="step1btn"
                                  onClick={(e) =>
                                    handleNext(e, questionId, "skip")
                                  }
                                >
                                  Skip Question
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              </div>
                              <div className="next-prev">
                                <button className="next" type="button" id="step1btn"
                                  onClick={(e) => handleNext(e, questionId, "submit")}
                                >
                                  Submit
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="next-prev-butt">
                              <div className="next-prev">
                                <button
                                  className="next"
                                  type="button"
                                  id="step1btn"
                                  onClick={(e) =>
                                    handleNext(e, questionId, "skip")
                                  }
                                >
                                  Skip Question
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              </div>

                              <div className="next-prev">
                                <button
                                  className={
                                    !answerSelected ? "next-disabled" : "next"
                                  }
                                  type="button"
                                  id="step1btn"
                                  onClick={(e) => handleNext(e, questionId)}
                                // disabled={!answerSelected}
                                >
                                  Next Question
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </section>
                      {/* <Modal
                        show={show3}
                        onHide={handleClose3}
                        className="home-model-answer"
                        keyboard={false}
                        backdrop="static"
                      >
                        <Modal.Header className="quiz-modal-header">
                          <img className="back-image" src={backImage} alt="" />
                          <Modal.Title className="quiz-modal-heading">
                            <div className="heading-model-result">
                              <h2>Results</h2>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="Quiz-modal-body">
                            <div className="quiz-flex">
                              <div className="points-score">Points Scored</div>
                              <div className="points-number">
                                {resultresponsedata.points_scored}
                              </div>
                            </div>
                            <div className="quiz-flex">
                              <div className="points-score">
                                Correct Answers
                              </div>
                              <div className="points-number">
                                {resultresponsedata.correct_answers}
                              </div>
                            </div>
                            <div className="quiz-flex">
                              <div className="points-score">
                                Incorrect Answers
                              </div>
                              <div className="points-number">
                                {resultresponsedata.incorrect_answers}
                              </div>
                            </div>
                            <div className="quiz-flex">
                              <div className="points-score">
                                Skipped Answers
                              </div>
                              <div className="points-number">
                                {resultresponsedata.skipped_answers}
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="home-modal-footer">
                          <Button
                            className="fl-btn st-6"
                            id="dashboard-butt"
                            onClick={Handlesub}
                          >
                            <span className="inner">Go Back To Dashboard</span>
                          </Button>
                        </Modal.Footer>
                      </Modal> */}

                      {/* result modal */}
                      <Modal show={show3} onHide={handleClose3} backdrop="static" keyboard={false} className="Result_mod" >
                        <Modal.Body className="payForm">
                          <div className="row payment">
                            <div className="col-md-12">
                              <div className="headLine">Exam Submitted successfully.</div>
                              {/* <div className="underLine"></div> */}
                            </div>
                            <div className="col-md-12 mb-2">
                              <h3 className="text-black-head-pay fw-semibold examtest-resultm">Exam result will be declared on <span className="examtest-moment">
                                {moment(resultModalDate).format('DD-MM-YYYY')}
                              </span>.</h3>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="home-modal-footer resultFooter">
                          <Button onClick={Handlesub} className="fl-btn st-6" id="dashboard-butt"  >
                            <span className="inner">Go Back To Dashboard</span>
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </form>
                </div>
              </Fragment>
            );
          })}
        </div>
      </main>
      <Modal show={imageModal} onHide={handleCloseImageModal} className="Quiz-image-modal" >
        <Modal.Body className="payForm">
          <div className="closeBtn" onClick={() => handleCloseImageModal()}>
            <img src="images/close (1).webp" alt="" />
          </div>
          <div className="row payment Quiz-image-modal-img">

            <img src={imageModal} alt="img" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Exam;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MobileVersion.css";
import logo from "../../Components/Common/images/logo/quiz-logo.webp";
import { AppleStoreLink, GoogleStoreLink, RemoveUsersession } from "../../Components/utils/utils";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
const MobileVersion = () => {
    const [mob, setMob] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const getMobileVersion = () => {
        setLoading(true)
        setTimeout(() => {
            const ua = window.navigator.userAgent;
            if (ua.includes("Pixel") || ua.match(/Pixel/i) || ua.match(/Android/i) || ua.match(/Windows/i) || ua.match(/Windows Phone/i) || ua.includes('Pixel') || ua.includes('Android') || ua.includes('Windows') || ua.includes('Windows Phone')) {
                setMob("Android");
            }
            if (/iPhone|iPad|iPod/i.test(ua) || ua.match(/iPhone/i) || ua.match(/iPad/i) || ua.match(/iPod/i) || ua.includes('iPhone') || ua.includes('iPad') || ua.includes('iPod')) {
                setMob("iOS");
            }
            setLoading(false);
        }, 200);
    }
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    useEffect(() => {
        if (width > 1200) {
            RemoveUsersession();
            navigate("/");
        } else {
            getMobileVersion();
        }
    }, [width])
    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <div className="mob-version-cont">
                <img src={logo} alt="logo" className="header-three_logo" />
                <div className="mt-5">For better Performance. Download our app.</div>
                {
                    mob === "Android" ?
                        <div className="google_apple_div google-apple-mob-ver">
                            <a href={GoogleStoreLink()} className="google_play_butt">
                                <div className="google-logo-image">
                                </div>
                                <div className="goole_main_div">
                                    <p className="google_get_it">GET IT ON</p>
                                    <p className="btn_googlePlay">Google Play</p>
                                </div>
                            </a>
                        </div> : <div className="google_apple_div google-apple-mob-ver">
                            <a href={AppleStoreLink()} className="google_play_butt">
                                <div className="google-apple-image">
                                </div>
                                <div className="goole_main_div">
                                    <p className="google_get_it">Download on the</p>
                                    <p className="btn_googlePlay">App Store</p>
                                </div>
                            </a>
                        </div>
                }
            </div>
        </>
    )
}
export default MobileVersion;
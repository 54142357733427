/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import loginImage from "../../Components/Common/images/LoginImages/login-3.webp";
import { useLocation } from "react-router-dom";
import { ApiUrl, GetLocalStorage, GetPhoneNumber, SetLocalStorage } from "../../Components/utils/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom/dist";
// import Header from "../Header/Header";
// import Footer from "../Footer/Footer";
import FooterOne from "../../Components/HeaderOne/Footer/footerone";
import FooterTwo from "../../Components/HeaderTwo/Footer/footertwo";
import FooterThree from "../../Components/HeaderThree/footer/footerthree";
import HeaderOne from "../../Components/HeaderOne/Header/headerone";
import HeaderTwo from "../../Components/HeaderTwo/Header/headertwo";
import HeaderThree from "../../Components/HeaderThree/header/headerthree";
import Loader from "../../Components/Loader/Loader";
const Otp = () => {
  const location = useLocation();
  const path = location?.state?.path;
  const [loading, setloading] = useState(false);
  const newPath = `/${path}`;
  const [otp, setOtp] = useState("");
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [displayTime, setDisplayTime] = useState(false)
  const navigate = useNavigate();
  const mobNum = GetLocalStorage("userPhoneNo");
  // const SignupLoginData = JSON.parse(GetLocalStorage("UserData"));

  useEffect(() => {
    if (!mobNum) {
      navigate('/')
    }
  }, [])
  const validateFields = () => {
    if (!otp) {
      toast.error("Please enter OTP");
      return false;
    }
    if (otp.length < 6) {
      toast.error("Otp should be of 6 digit");
      return false;
    }
    return true;
  };
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (e.target.type === "number" && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };
  const handleOtp = (e) => {
    e?.preventDefault();
    if (!validateFields()) return;
    const data = {};
    data.mobile_no = GetPhoneNumber();
    data.otp = otp;
    setloading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/login.php?request=verify_otp`,
    };
    axios(option)
      .then((e) => {
        setloading(false)
        if (e?.data?.result_code === "FAILED") {
          toast.error(e?.data?.message); return;
        }
        SetLocalStorage("LoginToken", JSON.stringify(e.data.result_data.token))
        e.data.result_data.is_otp_verified = 'DN';
        SetLocalStorage("UserProfile", JSON.stringify(e.data.result_data))
        navigate("/SchoolDetails", { state: newPath });
        setTimeout(() => {
          toast.success("Registered Successfuly");
        }, 50);
      })
      .catch((err) => { setloading(false) });
  };
  const ResendOtp = (e) => {
    e?.preventDefault();
    setSecondsLeft(60);
    const data = {};
    data.mobile_no = GetPhoneNumber();
    setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/login.php?request=send_otp`,
    };
    axios(option)
      .then((e) => {
        setloading(false);
        toast.success("Otp Sent Again");
        setOtp("");
      })
      .catch((err) => { setloading(false); });
  };
  useEffect(() => {
    if (secondsLeft <= 0) {
      setDisplayTime(true);
    } else {
      setDisplayTime(false)
      const timer = setTimeout(() => {
        setSecondsLeft((secondsLeft) => secondsLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [secondsLeft]);
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      {
        path === "5-8" ? <HeaderOne /> : path === "9-10" ? <HeaderTwo /> : path === "11-12" ? <HeaderThree /> : <HeaderOne />
      }
      <div className="sign-up-container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          draggable
          bodyClassName="fs-4"
          theme="colored"
        />
        <section className="tf-section tf-message main-otp-div">
          <div className="container-fluid main-container-pad">
            <div className="row otp-row">
              <div className="col-md-12 otp-div">
                <div className="form-message section-signup-cstm-4">
                  <div className="col-md-6" id="image-login">
                    <section className="login-image-section">
                      <img
                        src={loginImage}
                        alt=""
                        className="login-image-main"
                      />
                    </section>
                  </div>
                  <div className="col-md-6" id="otp-div">
                    <div className="login-form-div-4 main-section-signup">
                      <h2 className="heading heading-create-acc">Enter OTP</h2>
                      <form
                        id="commentform"
                        className="comment-form"
                        onSubmit={handleOtp}
                      >
                        <div className="fx flex-wrap">
                          <fieldset className="name">
                            <input type="number" placeholder="Please enter your OTP" name="number" min="0" className="number input_border" maxLength="6" id="number" value={otp}
                              onKeyPress={inputHandler} onChange={(e) => setOtp(e.target.value)}
                            />
                          </fieldset>
                          <div className="forget-div">
                            {
                              !displayTime ? <span className="otp-timer">{secondsLeft === 60 ? "01:00" : `00:${secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}`}</span>
                                : null
                            }
                            <button className="link-login-otp" onClick={ResendOtp} disabled={!displayTime}>
                              Resend Otp
                            </button>
                          </div>
                          <div className="wrap-btn btn-signup-main">
                            <button type="submit" className="fl-btn st-6" onClick={handleOtp} >
                              <span className="inner">Submit</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {
        path === "5-8" ? <FooterOne /> : path === "9-10" ? <FooterTwo /> : path === "11-12" ? <FooterThree /> : <FooterOne />
      }
    </>
  );
};
export default Otp;
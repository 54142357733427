import React from "react";
import "./Footer.css";
import { AppleStoreLink, GoogleStoreLink } from "../../utils/utils";

const Footer =()=>{
    return(
        <>
        <div className="dash-footer">
            <div className="container">
                <div className="row dash-footer-row">
                    <div className="dash-footer-copyright">copyright © 2023, Scholar Monks</div>
                    <div className="dash-footer-last">
                        <a href={GoogleStoreLink()} className="dash-footer-google">
                            <img src="images/dashboard/playstore.webp" alt="play"/>
                            <div className="dash-footer-text"><span>android App</span>
                            google play store</div>
                        </a>
                        <a href={AppleStoreLink()} className="dash-footer-google">
                            <img src="images/dashboard/app-store.webp" alt="play"/>
                            <div className="dash-footer-text"><span>download on the</span>
                            apple store</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ApiUrl, GetLocalStorage, SetLocalStorage } from "../../Components/utils/utils";
import axios from "axios";
import { ccavenueBaseURL } from "../../Components/utils/utils";
import { appRedirectURLSignup } from "../../Components/utils/utils";
import './pay.css';
import moment from "moment";

const Pay = ({ setShow, orderData, examDataFind, failedUrl }) => {
    const html=document.querySelector("html");
    const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
    const [userData, setUserData] = useState();
    // const [msg, setMsg] = useState();
    // const redirectURL = "localhost"
    // const redirectURL = "app.scholarmonksquiz/app"
    const handleSignup = () => {
        const data = {};
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                Usertoken: LoginToken,
            },
            data: data,
            url: `${ApiUrl()}/student.php?request=get_profile`,
        };
        axios(option)
            .then((e) => {
                SetLocalStorage("UserProfile", JSON.stringify(e.data.result_data));
                setUserData(e?.data?.result_data);
            })
            .catch((err) => {
            })
    }
    useEffect(() => {
        handleSignup();
    }, [])

    const closeModal = (e) => {
        e?.preventDefault();
        setShow(false);
        html.style.overflow= "unset";
    }


    // const validateForm = () => {
    //     if (!userData?.name || userData?.name?.trim() === "") {
    //         toast.error("Please enter name"); return;
    //     }
    //     if (!userData?.mobile_no || userData?.mobile_no?.trim() === "") {
    //         toast.error("Please enter mobile number"); return;
    //     }
    //     if (!userData?.email || userData?.email?.trim() === "") {
    //         toast.error("Please enter email address"); return;
    //     }
    //     // if (!msg || msg?.trim() === "") {
    //     //     toast.error("Please enter message"); return;
    //     // }
    //     return true;
    // }

    return (
        <>
            <form className="payForm" method="post" id="customerData"
                action={`${ccavenueBaseURL}ccavRequestHandler.php`}
            // onSubmit={handleSubmit} // Add the onSubmit event handler
            >
                <div className="closeBtn" onClick={(e) => closeModal(e)}>
                    <img src="images/close (1).webp" alt="" />
                </div>
                <div className="row payment">
                    {/* hidden feilds */}
                    <div className="col-md-12">
                        <input type="hidden" name="tid" id="tid" readOnly />
                        <input type="hidden" name="merchant_id" value="2653592" />
                        <input type="hidden" name="order_id" value={orderData?.order_id} />
                        <input type="hidden" name="currency" value="INR" />
                        <input type="hidden" name="redirect_url" value={`${ccavenueBaseURL}ccavResponseHandler.php`} />

                        <input type="hidden" name="cancel_url" value={`${ccavenueBaseURL}ccavResponseHandler.php`} />
                        <input type="hidden" name="language" value="EN" />
                        <input type="hidden" name="billing_country" id="billing_country" value="India" maxLength="50" />
                        <input type="hidden" name="delivery_name" value="Scholar Monks" />
                        <input type="hidden" name="delivery_address" value="India" />
                        <input type="hidden" name="delivery_city" value="Mohali" />
                        <input type="hidden" name="delivery_state" value="Punjab" />
                        <input type="hidden" name="delivery_zip" value="100100" />
                        <input type="hidden" name="delivery_country" value="India" />
                        <input type="hidden" name="delivery_tel" value="1234567890" />
                        {/* <input type="hidden" name="merchant_param1" value="additional Info. 1" /> */}
                        <input type="hidden" name="merchant_param2" value="additional Info. 1" />
                        <input type="hidden" name="merchant_param3" value="web" />
                        <input type="hidden" name="merchant_param4" value={appRedirectURLSignup} />
                        <input type="hidden" name="merchant_param5" value={failedUrl} />
                        <input type="hidden" name="action" value="register" />
                        <input type="hidden" name="promo_code" value="" />
                        <input type="hidden" name="customer_identifier" value="" />
                        <input value={userData?.city_name} loginregex="true" name="billing_city" id="billing_city" type="hidden" maxLength="30" />
                        <input value="122016" zipregex="true" name="billing_zip" id="billing_zip" type="hidden" maxLength="15" />
                        <input value={userData?.state_name} loginregex="true" name="billing_state" id="billing_state" type="hidden" maxLength="30" />
                        <input value="Scholar Monks" loginregex="true" name="billing_address" id="billing_address" type="hidden" maxLength="150" />
                    </div>
                    {/* visible feilds */}
                    <div className="col-md-12">
                        <div className="headLine">Payment</div>
                        <div className="underLine"></div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <h4 className="text-black-head-pay fw-semibold">Exam Title</h4>
                        <h4 className="text-black-pay"> {examDataFind?.title}</h4>
                        <input value={userData?.name} className="titleInp" loginregex="true" name="billing_name" id="billing_name" type="hidden" maxLength="50" />
                    </div>
                    <div className="col-md-12 mb-2 mt-2">
                        <h4 className="text-black-head-pay fw-semibold">Exam description</h4>
                        <h4 className="text-black-pay"> {examDataFind?.description}</h4>
                    </div>
                    <div className="col-md-12 mb-2 mt-2">
                        <h4 className="text-black-head-pay fw-semibold">Exam Dates</h4>
                        <h4 className="text-black-pay"> {examDataFind?.exam_month ? examDataFind?.exam_month : `${moment(examDataFind?.exam_from).format("LL")} to ${moment(examDataFind?.exam_to).format("LL")}`}</h4>
                    </div>
                    <div className="col-md-12 mb-2  mt-2">
                        <h4 className="text-black-head-pay fw-semibold">Amount Payable</h4>
                        <h4 className=" text-black-pay">Rs. {examDataFind?.exam_payment}</h4>
                        <input defaultValue={examDataFind?.exam_payment } className="titleInp" min="500" name="amount" id="amount" type="hidden" number="true" maxLength="10" />
                    </div>
                    <div className="col-md-12">
                        {/* <h6 className="title">Phone *</h6> */}
                        <input value={userData?.mobile_no} className="titleInp" maxLength="10" digits="true" name="billing_tel" id="billing_tel" type="hidden" />
                    </div>
                    <div className="col-md-12">
                        {/* <h6 className="title">Email *</h6> */}
                        <input value={userData?.email} className="titleInp" name="billing_email" id="billing_email" type="hidden" maxLength="70" />
                    </div>
                    <div className="col-md-12">
                        {/* <h6 className="title">Message *</h6> */}
                        {/* <textarea value={msg} onChange={(e) => setMsg(e?.target?.value)} className="titleInp msg w-100" name="txtExtraNotes" id="txtExtraNotes"></textarea> */}
                    </div>
                    <button className="payBtn fl-btn st-d mt-3">
                        <span className="inner">Pay Now</span>
                    </button>
                </div>
            </form >

        </>
    )
}
export default Pay;
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiUrl, SetLocalStorage } from "../../Components/utils/utils";
import axios from "axios";

const LoginWithEmail = ({ path }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [OpenPassword, setOpenPassword] = useState(false);

  const Eyetoggle = () => {
    setOpenPassword(!OpenPassword);
  };

  const validateFields = () => {
    if (userName.trim() === "") {
      toast.error("Please enter your Email");
      return false;
    }

    if (password.trim() === "") {
      toast.error("Please enter your password");
      return false;
    }
    return true;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    const data = {};
    data.username = userName;
    data.code = password;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/login.php?request=login_by_code`,
    };
    axios(option)
      .then((e) => {
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          // setTimeout(() => {
          //   toast.success("Logged In Successfully");
          // }, 500);
          SetLocalStorage("LoginToken", JSON.stringify(e?.data.result_data.token));
          SetLocalStorage("UserProfile", JSON.stringify(e?.data.result_data));
          if (e?.data?.result_data?.is_otp_verified !== 'DN') {
            navigate("/otp", { state: { source: "login", path: path } });
          } else if (e?.data?.result_data?.is_school_filled !== 'DN') {
            navigate("/SchoolDetails", { state: path });
          } else if (e?.data?.result_data?.is_payment_done !== 'DN') {
            navigate("/SchoolDetails", { state: path });
          } else {
            navigate("/dashboard", { state: path });
          }
        }
      })
      .catch((err) => { });
    // toast.success("Logged In Successfully");
  };

  const navigatetoForgotPassword = () => {
    if (path?.endsWith("/5-8")) {
      navigate("/forgotpassword", { state: "5-8" })
    } else if (path?.endsWith("/9-10")) {
      navigate("/forgotpassword", { state: "9-10" })
    } else if (path?.endsWith("/11-12")) {
      navigate("/forgotpassword", { state: "11-12" })
    } else {
      navigate("/forgotpassword", { state: "5-8" })
    }
  }
  return (
    <div>
      <div className="login-form-div">
        <form id="commentform1" className="comment-form">
          <div className="fx flex-wrap">
            <fieldset className="email">
              <input
                type="email"
                placeholder="User Name"
                required
                name="mail"
                className="mail input_border"
                id="mail"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </fieldset>
            <fieldset className="password">
              <input
                type={OpenPassword === false ? "password" : "text"}
                placeholder="Password"
                value={password}
                required
                name="password1"
                className="password-1 input_border"
                id="password1"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
              />{" "}
              <span className="eye">
                {OpenPassword === false ? (
                  <AiOutlineEyeInvisible onClick={Eyetoggle} />
                ) : (
                  <AiOutlineEye onClick={Eyetoggle} />
                )}
              </span>
            </fieldset>

            <div className="forget-div">
              {/* <div className="link-login" onClick={navigatetoForgotPassword}>
                Forgot Password?
              </div> */}
            </div>

            <div className="wrap-btn btn-signup-main">
              <button
                type="submit"
                className="fl-btn st-6"
                onClick={handleLogin}
              >
                <span className="inner">Sign In</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginWithEmail;

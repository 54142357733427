import React from "react";



const PayProcess = () => {
    return (
        <>
        </>
    )
}
export default PayProcess;
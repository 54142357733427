/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Banner.css";
import { useNavigate } from "react-router-dom";
import { ApiUrl, GetLocalStorage, SetLocalStorage } from "../../utils/utils";
import axios from "axios";
import { toast } from "react-toastify";

const Banner = ({ path, setloading }) => {
  const navigate = useNavigate();
  const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
  const [profileData, setProfileData] = useState("");
  const [rank, setRank] = useState();
  const [score, setscore] = useState();

  const GetProfile = () => {
    const data = {};
    setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/student.php?request=get_profile`,
    };
    axios(option)
      .then((e) => {
        setloading(false)
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          navigate("/SchoolDetails", { state: path });
          setTimeout(() => {
            toast.error("Please fill your details first");
          }, 50);
        }
        if (e?.data?.result_code === "OK") {
          SetLocalStorage("UserProfile", JSON.stringify(e.data.result_data));
          SetLocalStorage("LoginStatus", "true");
          setProfileData(e.data.result_data);
        }
      }).catch((err) => { setloading(false) });
  };

  const StudentRank = () => {
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: {},
      url: `${ApiUrl()}/start_quiz.php?request=get_student_rank`,
    };
    axios(option)
      .then((e) => {
        const rank = e?.data?.result_data?.rank;
        setRank(rank);
        const score = e?.data?.result_data?.score;
        setscore(score);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
        }
        if (e?.data?.result_code === "OK") {
          setRank(e?.data?.result_data?.rank);
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {
    GetProfile();
    StudentRank();
  }, []);

  return (
    <>
      <div className="dashbanner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 dashcols">
              <h1 className="dashwelcome">Welcome back</h1>
              <h1 className="dashwelcome dashuser">{profileData?.name}</h1>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.<span style={{ color: "red" }}>(P)</span></p> */}
              <div className="score-rank">
                <div>score: {score}</div>
                <div>|</div>
                <div>rank: {rank}</div>
              </div>
            </div>
            <div className="col-md-6 dash-banner-img">
              <img src="images/dashboard/student.webp" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Banner;
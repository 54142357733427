import React, { useRef, useState } from "react";
import "./DashboardNew.css";
import Header from "../../Components/DashboardNew/Header/Header";
import Banner from "../../Components/DashboardNew/Banner/Banner";
import MockTest from "../../Components/DashboardNew/MockTest/MockTest";
import ExamTest from "../../Components/DashboardNew/ExamTest/ExamTest";
import Footer from "../../Components/DashboardNew/Footer/Footer";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
const DashboardNew = () => {
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const examSection=useRef(null)
  return (
    <>
      {
        loading ? <Loader /> : null
      }
     
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        draggable
        bodyClassName="fs-4"
        theme="colored"
      />
      <Header path={location?.state} />
      <Banner path={location?.state} setloading={setloading} />
      <MockTest examSection={examSection}/>
      <ExamTest examRef={examSection}/>
      <Footer />
    </>
  )
}
export default DashboardNew;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import './headertwo.css';
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Common/images/logo/quiz-logo.webp";

const HeaderTwo = () => {
    const path = window.location.pathname;
    const navigate = useNavigate()
    const [toogle, setToogle] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);

    const handleToogle = () => {
        toogle ? setToogle(false) : setToogle(true)
    }
    const navigatetoSignup = () => {
        if (path?.endsWith("/5-8")) {
            navigate("/signup", { state: "5-8" })
        } else if (path?.endsWith("/9-10")) {
            navigate("/signup", { state: "9-10" })
        } else if (path?.endsWith("/11-12")) {
            navigate("/signup", { state: "11-12" })
        } else {
            navigate("/signup", { state: "5-8" })
        }
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (width < 1200) {
            navigate("/mobileVersion")
        }
    }, [width])

    return (
        <>
            <div className="header_two_container">
                <div className="container">
                    <div className="header-wrap">
                        <div className="header_start">
                            <div className="logo">
                                <Link><img src={logo}
                                    // onError={(e) => { e.target.src = "images/dashboard/dash-logo.webp" }}
                                    alt='logo' ></img></Link>
                            </div>
                            <div className="menu">
                                <button onClick={handleToogle}><img src={toogle ? 'images/close (1).webp' : 'images/menu (1).webp'} alt="menu" /></button>
                                <a href="https://scholarmonksquiz.com/9-10/">Home</a>
                                <a href="https://scholarmonksquiz.com/9-10/about.php">About us</a>
                                <a href="https://scholarmonksquiz.com/9-10/contact.php">contact us</a>
                                <Link to="/9-10">Login</Link>
                                <div className="sign_up" onClick={navigatetoSignup}>Sign up</div>
                            </div>
                        </div>
                    </div>
                    {
                        toogle ? <>
                            <ul className="links-ul header-links-ul ">
                                <li>
                                    <a href="https://scholarmonksquiz.com/9-10/">Home</a>
                                    <a href="https://scholarmonksquiz.com/9-10/about.php">About us</a>
                                    <a href="https://scholarmonksquiz.com/9-10/contact.php">contact us</a>
                                    <Link to="/9-10">Login</Link>
                                    <div className="sign" onClick={navigatetoSignup}>Sign up</div>
                                </li>
                            </ul></> : null
                    }
                </div>
            </div>
        </>
    )
}

export default HeaderTwo;
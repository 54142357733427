import React from 'react';
import './footerone.css';
import { AiOutlinePhone } from 'react-icons/ai';
import { BiEnvelope } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import { TbArrowBigUp } from "react-icons/tb"
import { AppleStoreLink, GetLocalStorage, GoogleStoreLink } from '../../utils/utils';
const FooterOne = () => {
    const baseURL = "https://scholarmonksquiz.com/5-8/";
    const LoginToken = GetLocalStorage("LoginToken");
    const scrollUp = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div className='footer_three'>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 footer_three_col1">
                        <img src="images/logofootert.webp" alt='logo' />
                        <p className='footer_three_para'>We are used by more than 20 million people per month in schools, homes around the world.</p>
                        <div className='footer_three_cont_info'>
                            <div className="footer_three_info mb-4">
                                <BiEnvelope className='footer_three_call' />
                                <a href="mailto:info@scholarmonksquiz.com">info@scholarmonksquiz.com</a>
                            </div>
                            <div className="footer_three_info d-flex">
                                <AiOutlinePhone className='footer_three_call' />
                                <a href="tel:9893472716">9893472716</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 footer_three_col2">
                        <h6>Useful Links</h6>
                        <div className="footer_three_usefull_links">
                            <Link target={LoginToken ? '_self' : '_blank'} to={baseURL}>Home</Link>
                            <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}about.php`} >About Us</Link>
                            <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}contact.php`} >Contact</Link>
                            <Link target={LoginToken ? '_self' : '_blank'} to="/5-8">Login</Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 footer_three_col3">
                        <h6>More Links</h6>
                        <div className="footer_three_usefull_links">
                            <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}terms-and-conditions.php`}>Terms And Conditions</Link>
                            <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}privacy-policy.php`} >Privacy Policy</Link>
                            <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}refund-policy.php`}>Refund Policy</Link>
                            <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}disclaimer.php`} >Disclaimer</Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12 firstCol">
                        <div className="google_apple_div-1">
                            <a href={GoogleStoreLink()} className="google_play_butt">
                                <div className="google-logo-image">
                                </div>
                                <div className="goole_main_div">
                                    <p className="google_get_it">GET IT ON</p>
                                    <p className="btn_googlePlay">Google Play</p>
                                </div>
                            </a>
                            <a href={AppleStoreLink()} className="google_play_butt">
                                <div className="google-apple-image">
                                </div>
                                <div className="goole_main_div">
                                    <p className="google_get_it">Download on the</p>
                                    <p className="btn_googlePlay">App Store</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row footer_three_bottom'>Copyright © 2023, Scholar Monks</div>
                <div className="copyright-arrow_footerone" onClick={scrollUp}>
                    {" "}
                    <TbArrowBigUp className="last-arrow-footer" />{" "}
                </div>
            </div>
        </div>
    )
}
export default FooterOne;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Header from "../Header/Header";
import "../../Components/Common/css/responsive.css";
import { ToastContainer, toast } from "react-toastify";
import { ApiUrl, SetLocalStorage } from "../../Components/utils/utils";
import axios from "axios";
import HeaderOne from "../../Components/HeaderOne/Header/headerone";
import FooterOne from "../../Components/HeaderOne/Footer/footerone";
import HeaderTwo from "../../Components/HeaderTwo/Header/headertwo";
import HeaderThree from "../../Components/HeaderThree/header/headerthree";
import FooterTwo from "../../Components/HeaderTwo/Footer/footertwo";
import FooterThree from "../../Components/HeaderThree/footer/footerthree";
import Loader from "../../Components/Loader/Loader";
// import Footer from "../Footer/Footer";
const ForgotPassword = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.state;
  const [loading, setloading] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [displayTime, setDisplayTime] = useState(false)


  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (e.target.type === "number" && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };
  const inputHandlerOtp = (e) => {
    const { value, maxLength } = e.target;
    if (e.target.type === "number" && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };
  const validateFields = () => {
    if (mobileNumber.trim() === "") {
      toast.error("Please enter your mobile number");
      return false;
    }
    if (mobileNumber.length < 10) {
      toast.error("Please enter your correct mobile number");
      return false;
    }

    return true;
  };
  const validatefieldsotp = () => {
    if (otp.trim() === "") {
      toast.error("Please enter otp");
      return false;
    }
    if (otp.length < 6) {
      toast.error("Otp should be of 6 character");
      return false;
    }
    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    setSecondsLeft(60);
    const data = {};
    data.mobile_no = mobileNumber;
    setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/login.php?request=send_otp`,
    };
    axios(option)
      .then((e) => {
        setloading(false)
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          toast.success("Otp Sent");
          setShowOtpInput(true);
        }
      })
      .catch((err) => { setloading(false) });


  };

  const handleOtpSubmit = (e) => {
    e?.preventDefault();
    if (!validatefieldsotp()) return;
    const data = {};
    data.mobile_no = parseInt(mobileNumber);
    data.otp = otp;
    setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/login.php?request=verify_otp`,
    };
    axios(option)
      .then((e) => {
        setloading(false)
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          SetLocalStorage("ForgetPasswordToken", JSON.stringify(e.data.result_data.token));
          navigate("/changepassword", { state: path });
          setTimeout(() => {
            toast.success("Otp Verified ");
          }, 50);
        }
      })

      .catch((err) => { setloading(false) });
  };

  useEffect(() => {
    if (secondsLeft <= 0) {
      setDisplayTime(true);
    } else {
      setDisplayTime(false)
      const timer = setTimeout(() => {
        setSecondsLeft((secondsLeft) => secondsLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [secondsLeft]);
  return (
    <>
      {
        loading ? <Loader /> : null
      }

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          draggable
          bodyClassName="fs-4"
          theme="colored"
        />

        {
          path === "5-8" ? <HeaderOne /> : path === "9-10" ? <HeaderTwo /> : path === "11-12" ? <HeaderThree /> : <HeaderOne />
        }
        <section
          className="tf-section-1 tf-message change-passoword-div"
          id="forgot-pass"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="form-message section-signup-cstm-3">
                  <div className="changepassword-form-message">
                    <div className="login-form-div-3 main-section-signup">
                      <h2 className="heading heading-create-acc">
                        Forgot Password
                      </h2>
                      <form id="commentform" className="comment-form">
                        <div className="fx flex-wrap">
                          {showOtpInput ? (
                            <><fieldset className="phone mb-2">
                              <label className="phone-head" id="otp">
                                Please Enter Your OTP
                              </label>
                              <input type="number" placeholder="Please enter your OTP" name="number" className="number input_border" maxLength="6" id="otp" value={otp}
                                onKeyPress={inputHandlerOtp} onChange={(e) => setOtp(e.target.value)} />
                            </fieldset>
                              <div className="forget-div">
                                {
                                  !displayTime ? <span className="otp-timer">{secondsLeft === 60 ? "01:00" : `00:${secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}`}</span>
                                    : null
                                }
                                <button className="link-login-otp" onClick={handleSubmit} disabled={!displayTime}>
                                  Resend Otp
                                </button>
                              </div>
                            </>
                          ) : (
                            <fieldset className="phone">
                              <label className="phone-head" id="number">
                                Enter Your Phone Number
                              </label>
                              <input type="number" placeholder="Phone Number" name="number" className="number input_border" id="number" min="0" value={mobileNumber} maxLength="10"
                                onKeyPress={inputHandler} onChange={(e) => setMobileNumber(e.target.value)} />
                            </fieldset>
                          )}

                          <div
                            className="wrap-btn btn-signup-main"
                            id="forget-butt"
                          >
                            {showOtpInput ? (
                              <button
                                type="submit"
                                className="fl-btn st-6"
                                onClick={handleOtpSubmit}
                                id="validate-otp-butt"
                              >
                                <span className="inner">Validate OTP</span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="fl-btn st-6"
                                onClick={handleSubmit}
                                id="foget-submit-butt"
                              >
                                <span className="inner">Submit</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          path === "5-8" ? <FooterOne /> : path === "9-10" ? <FooterTwo /> : path === "11-12" ? <FooterThree /> : <FooterOne />
        }
      </div>
    </>
  );
};
export default ForgotPassword;

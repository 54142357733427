/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./MockTest.css";
import { HiArrowNarrowRight } from "react-icons/hi"
import { ApiUrl, GetLocalStorage, ImageUrl, RemoveLocalStorage, SetLocalStorage } from "../../utils/utils";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import Slider from "react-slick";
import Animation from "../../Animation/Animation";

const MockTest = ({ examSection }) => {
    
    const [Subjectlistdata, setSubjectlistdata] = useState([]);
    const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
    const [selectedSubjectId, setSelectedSubjectId] = useState(null);
    const [show, setShow] = useState(false);
    const [examCount, setexamCount] = useState();
    const [mockCount, setmockCount] = useState();
    const mockSection = useRef(null);
    const [aniModal, setaniModal] = useState(false);
    const [h2, seth2] = useState();
    const html = document.querySelector("html");

    const Subjectlist = () => {
        const data = {};
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                Usertoken: LoginToken,
            },
            data: data,
            url: `${ApiUrl()}/listing.php?request=subject_list`,
        };
        axios(option)
            .then((e) => {
                
                if (e?.data?.result_code === "OK") {
                    setSubjectlistdata(e.data.result_data);
                }
                SetLocalStorage("UserData", JSON.stringify(e.data.result_data));
            })
            .catch((err) => { });
    };
    useEffect(() => {
        Subjectlist();
        RemoveLocalStorage('resultId')
        RemoveLocalStorage('result_data')
        RemoveLocalStorage('totalQuestions')
    }, []);
    const handleSubjectClick = (subjectId) => {
        setSelectedSubjectId(subjectId);
        setShow(true);
        html.style.overflow = "hidden";
    };
    const handleClose = () => { html.style.overflow = "unset"; setShow(false); }
    const QuizQues = async () => {
        // if(GetLocalStorage('result_data')){
        //     alert("already taking test"); return;
        // }
        const data = {};
        data.subject_id = selectedSubjectId;
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                Usertoken: LoginToken,
            },
            data: JSON.stringify(data),
            url: `${ApiUrl()}/start_quiz.php?request=start_quiz_mock`,
        };
        try {
            const response = await axios(option);
            if (
                response?.data?.result_code === "FAILED" ||
                response?.data?.result_code === "ERROR"
            ) {
            }
            if (response?.data?.result_code === "OK") {
                // setResultId(response.data.result_data.result_id);
                setShow(false);
                SetLocalStorage("resultId", response.data.result_data.result_id);
                SetLocalStorage("result_data", JSON.stringify(response.data.result_data));
                SetLocalStorage("totalQuestions", JSON.stringify(response?.data?.result_data?.data?.length));
                // Open the quiz page in a new window
                const quizUrl = "quiz";
                const windowFeatures =
                    "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=" +
                    window.screen.availWidth +
                    ",height=" +
                    window.screen.availHeight;
                window.open(quizUrl, "_blank", windowFeatures);
                html.style.overflow = "unset";
            }
        } catch (error) {
            // handle error
        }
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    // --------get student rank--------//
    const StudentRank = () => {
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                Usertoken: LoginToken,
            },
            data: {},
            url: `${ApiUrl()}/start_quiz.php?request=get_student_rank`,
        };
        axios(option)
            .then((e) => {
                const exam = e?.data?.result_data?.completed_exam_count;
                setexamCount(exam)
                const mock = e?.data?.result_data?.completed_mock_count;
                setmockCount(mock)
            })
            .catch((err) => { });
    };
    useEffect(() => {
        StudentRank();
    }, []);
    useEffect(() => {
        if (examCount === 0 && mockCount === 0) {
            seth2("Great, you are all logged in. Are you ready to take your first mock test?")
            if (!aniModal) {
                setaniModal(true);
            }
        }
        if (examCount === 0 && mockCount > 0) {
            seth2("You did great in your mock test, are you now ready to take your Live Quiz and be eligible to amazing rewards? or Take another mock test now")
            if (!aniModal) {
                setaniModal(true);
            }
        }
    }, [examCount, mockCount]);

    // useEffect(() => {
    //     if (aniModal) {
    //         setTimeout(() => {
    //             setaniModal(false);
    //         }, 60000);
    //     }
    // }, [aniModal])
    const scrollToMock = () => {
        setaniModal(false)
        setTimeout(() => {
            mockSection?.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 500)
    }
    const scrollToExam = () => {
        setaniModal(false)
        setTimeout(() => {
            examSection?.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 500)
    }

    

    return (
        <>
            {
                aniModal ? <Animation aniModal={aniModal} setaniModal={setaniModal} h2={h2} startMock={() => handleSubjectClick(Subjectlistdata?.[1]?.subject_id)} scrollToMock={() => scrollToMock()} scrollToExam={() => scrollToExam()} /> : null
            }
            <div id="dash-mock" ref={mockSection}>
                <div className="container">
                    <div className="row dash-mock-row">
                        <h1>Mock Test</h1>
                        <div className="dash-mock-para">Step into the world of challenge and preparation with our exhilarating mock tests! Designed to mirror the excitement of a real exam, our mock tests provide the perfect platform for you to showcase your knowledge and skills. Discover the thrill of testing yourself under simulated exam conditions, gaining valuable insights into your strengths and areas for improvement. Get ready to soar to new heights of confidence and success as you embark on this empowering journey of preparation!
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className=" col-12 dash-mock-slider">
                            {
                                Subjectlistdata?.length ?
                                    <Slider {...settings}>
                                        {
                                            Subjectlistdata?.map((item, index) => (
                                                <div key={index} className="dash-mock-test-one">
                                                    <div key={index} className="dash-mock-card row">
                                                        {/* <img src={mockImage=== item?.mock_id ? "images/dashboard/dash-mock-1.webp":`${ImageUrl()}${item?.mock_image}` } alt="mt1" onError={()=>setMockImage(item?.mock_id)} /> */}
                                                        <img src={`${ImageUrl()}${item?.mock_image}`} alt="" onError={(e) => { e.target.src = "images/dashboard/dash-mock-1.webp" }} />
                                                        <div className="dash-mock-title">{item?.title}</div>
                                                        <p className="dash-mock-card-para">{item?.description}</p>
                                                        <div className="dash-mock-number-cont">
                                                            <div className="dash-mock-number">
                                                                <p>Total Questions: <span>{item?.total_questions}</span></p>
                                                                <p>Exam Duration: <span>{
                                                                    Math.floor(item?.time_per_question_in_sec * item?.total_questions % 3600 / 60) + " min"
                                                                }
                                                                    {/* <span style={{ color: "red" }}>(P)</span> */}
                                                                </span>
                                                                </p>
                                                            </div>
                                                            <div className="dash-mock-start" onClick={() => handleSubjectClick(item?.subject_id)}>Start<HiArrowNarrowRight /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider> : <div className="dash-examcols row">
                                        <div className="dash-exam-card1 col-6">
                                            <img src="images/dashboard/dash-exam.webp" alt="exm" className="no-exam-image" />
                                        </div>
                                        <div className="dash-exam-card2 col-6">
                                            <h1 className="card2-heading-no-exams">No Mock Test <br /><span>Available at</span><br />The Moment.</h1>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} className="home-model">
                    <Modal.Header className="home-model-header">
                        <Modal.Title className="home-modal-title">
                            <div className="modal-heading">
                                <p>Mock Test</p>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p id="rules-regulation"><strong>Rules & Regulation:</strong></p>
                        <p><strong>1: Technical Requirements:</strong> Inform students in advance about the technical requirements for taking the online mock
                            test. This may include a stable internet connection, compatible devices (laptops, tablets), and recommended browsers.
                        </p>
                        <p><strong>2: Time Limit:</strong> Clearly state the time limit for the test. Implement an automatic timer within the test platform to
                            enforce the time constraint.
                        </p>
                        <p><strong>3: No External Assistance:</strong> Prohibit students from seeking external help during the test.
                            They should complete the test independently, just as they would in an offline exam.
                        </p>
                        <p><strong>4: Technical Support:</strong> Provide a helpline or contact person for technical assistance in case students encounter any
                            issues during the test.
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="home-modal-footer">
                        <Button className="fl-btn st-6" disabled={GetLocalStorage('result_data')} onClick={QuizQues}>
                            <span className="inner">Start Mock Quiz</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
export default MockTest;
import React from 'react';
import './Animation.css'
import { useNavigate } from 'react-router-dom';
import { RemoveUsersession } from '../utils/utils';
const Animation = ({ setaniModal, h2, startMock, scrollToMock, scrollToExam }) => {
    const navigate = useNavigate();
    const close = () => {
        setaniModal(false);
    }
    const h2class = h2 === "Welcome to Scholar Monks" || h2 === "Great you are now all signed up!" ? "h2-small" : "h2-large"
    return (
        <div className="monks-animate-steps monks-common">
            {
                h2 && h2 !== "Great you are now all signed up!" ?
                    <button className="monks_close" onClick={() => close()}>
                        <img src="images/close.png" alt='close' />
                        </button> : null
            }

            <div className="monks-overlay monks-common"></div>
            <div className="slide-2 monks-welcome-1">
                <img src="images/monkLogo.svg" className="monk-1" alt="monk" />
                <div className="cloud-talk">
                    <div className="cloud-talk-content welcome_screen">
                        <h2 className={h2class}>{h2}<br />
                            {
                                h2 && h2 === "Great you are now all signed up!" ?
                                    <button onClick={() => { RemoveUsersession(); navigate('/') }} className="start_btn">Login</button> :
                                    h2 && h2 === "Great, you are all logged in. Are you ready to take your first mock test?" ?
                                        <button onClick={() => startMock()} className="start_btn">Start Mock</button> :
                                        h2 && h2?.includes("You did great") ?
                                            <>
                                                <button onClick={() => scrollToMock()} className="start_btn add_margin">Start Mock</button>
                                                <button onClick={() => scrollToExam()} className="start_btn">Start Exam</button>
                                            </> : null
                            }
                        </h2>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default Animation;
import React from "react";
import './footertwo.css';
import { Link } from "react-router-dom";
import logo from "../../Common/images/logo/quiz-logo.webp";
import { TbUpload } from "react-icons/tb"
import { AppleStoreLink, GetLocalStorage, GoogleStoreLink } from "../../utils/utils";
const FooterTwo = () => {
    const baseURL = "https://scholarmonksquiz.com/9-10/";
    const LoginToken = GetLocalStorage("LoginToken");
    const scrollUp = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (<>
        <footer className="footer_section">
            {/* <div className="LetterSection">
                <div className="container">
                    <div className="newsLetter">
                        <h2>Signups Going On ! Hurry Up To Start Your First Test</h2>
                        <div className="join">
                            <Link>Join Now</Link>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container">
                <div className="footer_top">
                    <div className="row footer_row">
                        <div className="col-md-6 col-xl-4 firstCol">
                            <Link className="logo"><img src={logo} alt='logo' /></Link>
                            <div className="logo_heading">We are used by more than 20 million people per month in schools, homes around the world.</div>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6 firstCol">
                            <h2>Get In Touch</h2>
                            <div className="contact_info">
                                <img src="images/email.svg" alt="email"></img>
                                <a href="mailto:info@scholarmonksquiz.com">info@scholarmonksquiz.com</a>
                            </div>
                            <div className="contact_info">
                                <img src="images/phone.svg" alt="phone"></img>
                                <a href="tel:9893472716" className="mob">9893472716</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-2 col-sm-6 firstCol">
                            <h2>Explore Links</h2>
                            <div className="contact_info explore">
                                <Link target={LoginToken ? '_self' : '_blank'} to={baseURL}>Home</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}about.php`} >About Us</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}contact.php`} >Contact</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to="/9-10">Login</Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 col-12 firstCol">
                            <div className="google_apple_div-2">
                                <a href={GoogleStoreLink()} className="google_play_butt">
                                    <div className="google-logo-image">
                                    </div>
                                    <div className="goole_main_div">
                                        <p className="google_get_it">GET IT ON</p>
                                        <p className="btn_googlePlay">Google Play</p>
                                    </div>
                                </a>
                                <a href={AppleStoreLink()} className="google_play_butt">
                                    <div className="google-apple-image">
                                    </div>
                                    <div className="goole_main_div">
                                        <p className="google_get_it">Download on the</p>
                                        <p className="btn_googlePlay">App Store</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="footer_bottom">
                    <div className="row">
                        <div className="col-lg-12 full_col">
                            <div className="copyright">
                                <div>© 2023 Copyright | All Rights Reserved</div>
                            </div>
                            <div className="links">
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}terms-and-conditions.php`}>Terms And Conditions</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}privacy-policy.php`} >Privacy Policy</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}refund-policy.php`}>Refund Policy</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}disclaimer.php`} >Disclaimer</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-arrow_footertwo" onClick={scrollUp}>
                    {" "}
                    <TbUpload className="last-arrow-footer" />{" "}
                </div>
            </div>
        </footer>
    </>)
}
export default FooterTwo;
import React from 'react'
import { Spinner } from 'react-bootstrap'
import './Loader.css'

const Loader = () => {
    return (
        <div className="loader_section">
            <Spinner />
        </div>
    )
}

export default Loader
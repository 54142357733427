import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Header from "../Header/Header";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { ApiUrl, GetLocalStorage, RemoveLocalStorage } from "../../Components/utils/utils";
import axios from "axios";
// import Footer from "../Footer/Footer";
import FooterOne from "../../Components/HeaderOne/Footer/footerone";
import FooterTwo from "../../Components/HeaderTwo/Footer/footertwo";
import FooterThree from "../../Components/HeaderThree/footer/footerthree";
import HeaderOne from "../../Components/HeaderOne/Header/headerone";
import HeaderTwo from "../../Components/HeaderTwo/Header/headertwo";
import HeaderThree from "../../Components/HeaderThree/header/headerthree";
import Loader from "../../Components/Loader/Loader";

const Changepassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.state;
  const newPath = `/${path}`;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [OpenCPassword, setOpenCPassword] = useState(false);
  const [OpenConPassword, setOpenConPassword] = useState(false);
  const tokenid = JSON.parse(GetLocalStorage("ForgetPasswordToken"));
  const ChangePassToken = JSON.parse(GetLocalStorage("LoginToken"));
  const [loading, setloading] = useState(false);

  const Eyetoggle2 = () => {
    setOpenCPassword(!OpenCPassword);
  };
  const Eyetoggle3 = () => {
    setOpenConPassword(!OpenConPassword);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFields = () => {
    if (newPassword.trim() === "") {
      toast.error("Please enter your new password");
      return false;
    }
    if (!newPassword.match(/[a-z]/)) {
      toast.error("Password must have minimum 8 characters and contain uppercase, lowercase, number & special character");
      return false;
    }
    if (!newPassword.match(/[A-Z]/)) {
      toast.error("Password must have minimum 8 characters and contain uppercase, lowercase, number & special character");
      return false;
    }
    if (!newPassword.match(/[0-9]/)) {
      toast.error("Password must have minimum 8 characters and contain uppercase, lowercase, number & special character");
      return false;
    }
    if (!newPassword.match(/[!@#$%^&*]/)) {
      toast.error("Password must have minimum 8 characters and contain uppercase, lowercase, number & special character");
      return false;
    }
    if (newPassword.length < 8) {
      toast.error("Password must have minimum 8 characters and contain uppercase, lowercase, number & special character");
      return false;
    }
    if (confirmPassword.trim() === "") {
      toast.error("Please enter your password again ");
      return false;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Password should match");
      return false;
    }
    return true;
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    if (!validateFields()) return;
    const data = {};
    data.new_password = newPassword;
    setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: tokenid ? tokenid : ChangePassToken,
      },
      data: data,
      url: `${ApiUrl()}/student.php?request=update_password_after_forget`,
    };
    axios(option)
      .then((e) => {
        setloading(false)
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          RemoveLocalStorage("ForgetPasswordToken");
          if (tokenid) {
            navigate(newPath);
          }
          navigate("/dashboard")
          setTimeout(() => {
            toast.success(e?.data?.message);
          }, 50);
        }
      })

      .catch((err) => { setloading(false) });
  };
  return (
    <>
      {
        loading ? <Loader /> : null
      }

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          draggable
          bodyClassName="fs-4"
          theme="colored"
        />{" "}
        {
          path === "5-8" ? <HeaderOne /> : path === "9-10" ? <HeaderTwo /> : path === "11-12" ? <HeaderThree /> : <HeaderOne />
        }
        <section className="tf-section-1 tf-message change-passoword-div">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="form-message section-signup-cstm-3">
                  <div className="changepassword-form-message">
                    {/* <section className="login-image-section"></section> */}
                    <div className="login-form-div-3 main-section-signup">
                      <h2 className="heading heading-create-acc">
                        Change Password
                      </h2>
                      <form id="commentform" className="comment-form">
                        <div className="fx flex-wrap">
                          <fieldset className="message">
                            <input
                              type={OpenCPassword === false ? "password" : "text"}
                              placeholder="New Password"
                              value={newPassword}
                              required
                              name="password"
                              className="password-1 input_border"
                              id="password3"
                              onChange={(e) => setNewPassword(e.target.value)}
                              autoComplete="off"
                            />{" "}
                            <span className="eye">
                              {OpenCPassword === false ? (
                                <AiOutlineEyeInvisible onClick={Eyetoggle2} />
                              ) : (
                                <AiOutlineEye onClick={Eyetoggle2} />
                              )}
                            </span>{" "}
                          </fieldset>
                          <fieldset className="message">
                            <input
                              type={
                                OpenConPassword === false ? "password" : "text"
                              }
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              required
                              name="password"
                              className="password-1 input_border"
                              id="password"
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              autoComplete="off"
                            />{" "}
                            <span className="eye">
                              {OpenConPassword === false ? (
                                <AiOutlineEyeInvisible onClick={Eyetoggle3} />
                              ) : (
                                <AiOutlineEye onClick={Eyetoggle3} />
                              )}
                            </span>
                          </fieldset>
                          <div className="wrap-btn btn-signup-main">
                            <button
                              type="submit"
                              className="fl-btn st-6 submit-but"
                              onClick={handleSubmit}
                            >
                              <span className="inner">Submit</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          path === "5-8" ? <FooterOne /> : path === "9-10" ? <FooterTwo /> : path === "11-12" ? <FooterThree /> : <FooterOne />
        }
      </div>
    </>
  );
};
export default Changepassword;

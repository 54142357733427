// const envoirnment = "local";
// const envoirnment = "test";
const envoirnment = "prod";

//Test Server
export const ccavenueBaseURL = envoirnment?.endsWith('test') || envoirnment?.endsWith("local") ? "https://scholarmonksquiz.com/ccavenue/" : "https://scholarmonksquiz.com/ccavenue-prod/";
export const appRedirectURLSignup = envoirnment?.endsWith("local") ? 'localhost' : (envoirnment?.endsWith('test') ? "quiz-app.mjtestserver.in/app" : "app.scholarmonksquiz.com/app");

// export const appRedirectURLSignup ="app.scholarmonksquiz.com/app";

export const getEnv = () => {
    return envoirnment
}
export const ApiUrl = () => {
    if (envoirnment === 'local' || envoirnment === "test") {
        return 'http://quiz-app.mjtestserver.in/api'
    } else {
        return 'https://app.scholarmonksquiz.com/api'
    }
}

export const userData = (UserData) => {
    localStorage.setItem('UserData', UserData)
}

export const userPhoneNo = (userPhoneNo) => {
    localStorage.setItem('userPhoneNo', userPhoneNo)
}

export const GetPhoneNumber = () => {
    return localStorage.getItem('userPhoneNo') || null;
}

export const RemoveUsersession = () => {
    RemoveLocalStorage("LoginToken");
    RemoveLocalStorage("ForgetPasswordToken");
    RemoveLocalStorage("UserData");
    RemoveLocalStorage("userPhoneNo");
    RemoveLocalStorage("resultId");
    RemoveLocalStorage("questionId");
    RemoveLocalStorage("examQuesId");
    RemoveLocalStorage("studentRank");
    RemoveLocalStorage("Exam");
    RemoveLocalStorage("result_data");
    RemoveLocalStorage("dashboardClicked");
    RemoveLocalStorage("totalQuestions");
    RemoveLocalStorage("UserProfile");
    RemoveLocalStorage("LoginStatus");
    RemoveLocalStorage('result_date');
    RemoveLocalStorage('loglevel');
    localStorage?.clear();
    // setTimeout(() => {
    //     window.location.reload()
    // }, 5);
}

export const ImageUrl = () => {
    if (envoirnment === 'local' || envoirnment === "test") {
        return 'http://quiz-admin.mjtestserver.in/uploads/'
    } else {
        return 'https://console.scholarmonksquiz.com/uploads/'
    }
    // return 'http://tozy-env.eba-2reyyaht.us-west-2.elasticbeanstalk.com/quiz_monks_admin/uploads/'
}
export const GoogleStoreLink = () => {
    return 'https://play.google.com/store/apps/details?id=com.mrit.scholarmonks';
}
export const AppleStoreLink = () => {
    return 'https://apps.apple.com/us/app/scholar-monks-quiz/id6447765100';
}

//Set Local Storage
export const SetLocalStorage = (name, value) => {
    localStorage.setItem(name, value)
}
//Get Local Storage
export const GetLocalStorage = (name) => {
    return localStorage.getItem(name) || null;
}
//Remove Local Storage
export const RemoveLocalStorage = (name) => {
    localStorage.removeItem(name);
}


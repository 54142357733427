import React from "react";
import './footerthree.css';
import { Link } from "react-router-dom";
import logo from "../../Common/images/logo/quiz-logo.webp";
import { IoIosArrowUp } from "react-icons/io"
import { AppleStoreLink, GetLocalStorage, GoogleStoreLink } from "../../utils/utils";
const FooterThree = () => {
    const baseURL = "https://scholarmonksquiz.com/11-12/"
    const LoginToken = GetLocalStorage("LoginToken");
    // const path = window.location.pathname;
    // const navigate = useNavigate()
    // const navigatetoSignup = () => {
    //     if (path?.endsWith("/5-8")) {
    //         navigate("/signup", { state: "5-8" })
    //     } else if (path?.endsWith("/9-10")) {
    //         navigate("/signup", { state: "9-10" })
    //     } else if (path?.endsWith("/11-12")) {
    //         navigate("/signup", { state: "11-12" })
    //     } else {
    //         navigate("/signup", { state: "5-8" })
    //     }
    // }
    const scrollUp = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (<>
        <footer>
            <div className="top_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-8 col1">
                            <img src={logo} alt='logo' />
                            <p>We are used by more than 20 million people per month in schools, homes around the world.</p>
                            <div className="info mb-4">
                                <i className="fa fa-envelope icons"></i>
                                <a href="mailto:info@scholarmonksquiz.com">info@scholarmonksquiz.com</a>
                            </div>
                            <div className="info d-flex">
                                <i className="fa fa-mobile-alt icons"></i>
                                <a href="tel:9893472716">9893472716</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-8 col2">
                            <h6>Useful Links</h6>
                            <div className="usefull_links">
                                <Link target={LoginToken ? '_self' : '_blank'} to={baseURL}>Home</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}about.php`} >About Us</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}contact.php`} >Contact</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to="/11-12">Login</Link>
                                {/* <button onClick={navigatetoSignup}>SIGN UP</button> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-8 col2">
                            <h6>Useful Links</h6>
                            <div className="usefull_links">
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}terms-and-conditions.php`}>Terms And Conditions</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}privacy-policy.php`} >Privacy Policy</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}refund-policy.php`}>Refund Policy</Link>
                                <Link target={LoginToken ? '_self' : '_blank'} to={`${baseURL}disclaimer.php`} >Disclaimer</Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 col-12 col4 ">
                            <h6>Apps Link</h6>
                            <div className="google_apple_div">
                                <a href={GoogleStoreLink()} className="google_play_butt">
                                    <div className="google-logo-image">
                                    </div>
                                    <div className="goole_main_div">
                                        <p className="google_get_it">GET IT ON</p>
                                        <p className="btn_googlePlay">Google Play</p>
                                    </div>
                                </a>
                                <a href={AppleStoreLink()} className="google_play_butt">
                                    <div className="google-apple-image">
                                    </div>
                                    <div className="goole_main_div">
                                        <p className="google_get_it">Download on the</p>
                                        <p className="btn_googlePlay">App Store</p>
                                    </div>
                                </a>
                            </div>
                            {/* <div className="android">
                                <button className="btn btn-google">Google Play</button>
                            </div> */}
                            {/* <div className="android">
                                <button className="btn btn-apple">App Store</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6"><p>© 2023 All Rights Reserved by Scholar Monks.</p></div>
                        <div className="col-md-6 links_two">
                            <h6>Terms & Conditions</h6>
                            <h6>Privacy Policy</h6>
                            <h6>Disclaimer</h6>
                            <h6>Refund Policy</h6>
                        </div>
                    </div>
                </div>
                <div className="copyright-arrow_footerthree" onClick={scrollUp}>
                    {" "}
                    <IoIosArrowUp className="last-arrow-footer" />{" "}
                </div>
            </div>
        </footer>
    </>)
}
export default FooterThree;
import React, { useEffect, useState } from "react";
import "./profile.css";
import profile from "../../Components/Common/images/user (1).webp";
import { ApiUrl, GetLocalStorage, SetLocalStorage } from "../../Components/utils/utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/DashboardNew/Header/Header";
import Footer from "../../Components/DashboardNew/Footer/Footer";
import Loader from "../../Components/Loader/Loader";
import Select from "react-select";
import moment from 'moment';

const Profile = () => {
  const location = useLocation();
  const [profileData, setProfileData] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [PreviewImage, setPreviewImage] = useState();
  const [loading, setloading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [stateId, setStateId] = useState();
  const [statedata, setStatedata] = useState([]);
  const [schoolId, setSchoolId] = useState();
  const [schooldata, setSchoolData] = useState([]);
  const [classId, setClassid] = useState();
  const [classdata, setClassData] = useState([]);
  const [cityId, setcityId] = useState();
  const [city, setCity] = useState([]);
  const [dob, setDob] = useState();
  const [username, setUsername] = useState("")
  // const ProfileData = JSON.parse(GetLocalStorage("UserData"));
  const LoginToken = JSON.parse(GetLocalStorage("LoginToken"));
  // const imageUrl = `${ImageUrl()}${profileData.image}`;

  // --------signUp api-----------//
  const getProfileData = () => {
    setloading(true);
    const data = {};
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/student.php?request=get_profile`,
    };
    axios(option)
      .then((e) => {
        setloading(false);
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          navigate("/SchoolDetails", { state: location?.state });
          setTimeout(() => {
            toast.error("Please fill your details first");
          }, 50);
        }
        if (e?.data?.result_code === "OK") {
          SetLocalStorage("UserProfile", JSON.stringify(e.data.result_data));
          setProfileData(e.data.result_data);
          const data = e?.data?.result_data;
          setName(data?.name);
          setUsername(data?.username)
          setEmail(data?.email);
          setMobile(data?.mobile_no);
          setDob(data?.dob);
          const state = statedata?.find(item => item?.value === data?.state_id);
          setStateId(state);
        }
        SetLocalStorage("UserData", JSON.stringify(e.data.result_data));
      })
      .catch((err) => { setloading(false); });
  };

  // ---image preview----//
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
  };
  // --------post image api---------------//
  const handleupdate = () => {
    const formData = new FormData();
    formData.append("userfile", file);
    // setloading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "multipart/form-data",
        Usertoken: LoginToken,
      },
      data: formData,
      url: `${ApiUrl()}/signup.php?request=update_profile_image`,
    };
    axios(option)
      .then((e) => {
        setloading(false)
        if (
          e?.data?.result_code === "FAILED" ||
          e?.data?.result_code === "ERROR"
        ) {
          toast.error("Please select the image first");
        }
        if (e?.data?.result_code === "OK") {
          // navigate("/dashboard", { state: location?.state });
          // setTimeout(() => {
          //   toast.success("Image Uploaded");
          // }, 50);
        }
      })
      .catch((err) => {
        setloading(false)
        if (err.message === "Request failed with status code 413") {
          toast.error("File size too big");
        }
      });
  };
  const Poststatedata = () => {
    // setloading(true);
    const data = {};
    data.state_name = "";
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=states_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false);
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          const StateOptions = e?.data?.result_data?.map((state) => {
            return { value: Number(state?.state_id), label: state?.state_name };
          });
          setStatedata(StateOptions);
        }
      })
      ?.catch((err) => { setloading(false) });
  }
  useEffect(() => {
    Poststatedata()
  }, [])
  const Postcitydata = () => {
    // setloading(true);
    const data = {};
    data.city_name = ""; //cityname;
    data.state_id = stateId?.value;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=city_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false)
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          const CityOptions = e?.data?.result_data?.map((city) => {
            return { value: Number(city?.city_id), label: city?.city_name };
          });
          setCity(CityOptions);
        }
      })
      ?.catch((err) => { setloading(false) });
  }
  useEffect(() => {
    if (stateId?.value) {
      Postcitydata();
    }
  }, [stateId?.value]);
  useEffect(() => {
    if (city?.length && profileData) {
      const city1 = city?.find(item => item?.value === profileData?.city_id)
      setcityId(city1);
    }
  }, [city?.length])

  const Postschooldata = () => {
    setloading(true)
    const data = {};
    data.city_id = cityId?.value;
    data.state_id = stateId?.value;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=school_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false)
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {
          const SchoolList = e?.data?.result_data?.map((school) => {
            return { value: Number(school?.school_id), label: school?.school_name };
          });
          setSchoolData(SchoolList);
        }
      })
      ?.catch((err) => { setloading(false) });
  }
  useEffect(() => {
    if (cityId?.value) {
      Postschooldata();
    }
  }, [cityId?.value]);

  useEffect(() => {
    if (schooldata?.length && profileData) {
      const school = schooldata?.find(item => item?.value === profileData?.school_id)
      setSchoolId(school);
    }
  }, [schooldata])

  const Postclassdata = () => {
    setloading(true)
    const data = {};
    data.school_id = schoolId?.value;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: data,
      url: `${ApiUrl()}/listing.php?request=class_list`,
    };
    axios(option)
      ?.then((e) => {
        setloading(false)
        if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
          toast.error(e?.data?.message);
        }
        if (e?.data?.result_code === "OK") {

          const ClassList = e?.data?.result_data?.map((item) => {
            return { value: Number(item?.class_id), label: item?.class_name };
          });
          setClassData(ClassList);
        }
      })
      ?.catch((err) => { setloading(false) });
  }
  useEffect(() => {
    if (schoolId?.value) {
      Postclassdata();
    }
  }, [schoolId?.value]);

  useEffect(() => {
    if (classdata?.length && profileData) {
      const classd = classdata?.find(item => item?.value === profileData?.class_id)
      setClassid(classd);
    }
  }, [classdata])

  const validateProfileUpdate = () => {
    if (!name || name?.trim() === '') { toast.error("Please enter name"); return }
    if (name?.length > 60) {
      toast.error("Name should be less or equal to 60 characters"); return
    }
    if (!name.match(/^[a-zA-Z ]*$/)) {
      toast.error("Name should contain only alphabets and spaces"); return
    }
    if (!email || email?.trim() === '') { toast.error("Please enter email"); return }
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      toast.error("Please enter valid email"); return
    }
    if (email?.length > 70) {
      toast.error("Email should be less or equal to 70 characters"); return
    }
    if (!dob || dob?.trim() === '') { toast.error("Please enter your DOB"); return }
    const currentDate = new Date();
    // var minAllowedDate = new Date();
    var minAllowedDate = new Date(Date.now() - 3600 * 1000 * 24);
    minAllowedDate.setFullYear(currentDate.getFullYear() - 8);
    const select = moment(dob).format('MM/DD/YYYY')
    const valid = moment(minAllowedDate).format('MM/DD/YYYY')
    if (new Date(select) > new Date(valid)) {
      toast.error("You must be at least 8 years old");
      return false
    }
    if (!stateId || stateId === '') { toast.error("Please select state"); return }
    if (!cityId || cityId === '') { toast.error("Please select city"); return }
    if (!schoolId || schoolId === '') { toast.error("Please select school"); return }
    if (!classId || classId === '') { toast.error("Please enter class"); return }
    return true;
  }
  const updateProfile = () => {
    if (!validateProfileUpdate()) {
      return;
    }
    if (file) {
      handleupdate();
    }
    const data = {}
    data.name = name;
    data.email = email;
    data.dob = dob;
    data.state_id = (stateId?.value);
    data.city_id = (cityId?.value);
    data.school_id = (schoolId?.value);
    data.class_id = (classId?.value);
    const options = {
      method: "POST",
      headers: {
        'content-type': 'application/json',
        'access-control-allow-origin': '*',
        Usertoken: LoginToken,
      },
      data: data,
      url: `${ApiUrl()}/signup.php?request=update_profile`
    }
    axios(options)?.then((e) => {
      if (e?.data?.result_code === "FAILED" || e?.data?.result_code === "ERROR") {
      }
      if (e?.data?.result_code === "OK") {
        setTimeout(() => {
          toast.success(e?.data?.message);
        }, 100);
        getProfileData()
        // navigate('/dashboard');
      }
      SetLocalStorage("UserData", JSON.stringify(e.data.result_data));
    })?.catch((err) => { })
  }

  useEffect(() => {
    if (statedata?.length) {
      getProfileData();
    }
  }, [statedata]);


  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          draggable
          bodyClassName="fs-4"
          theme="colored"
        />
        <Header path={location?.state} />
        <section className="profile-section">
          <div className="profile-section-div">
            <h2 className="heading heading-create-acc profile-heading">
              Profile
            </h2>
            <div className="inner-container-profile">
              <div className="profile-flex-container">
                <div className="image-profile">
                  <img
                    className="profile-image-"
                    src={
                      PreviewImage
                        ? PreviewImage
                        : profileData?.image
                          ? `${profileData?.IMAGE_BASE_URL}${profileData.image}`
                          : profile
                    }
                    alt=""
                  />
                  <div className="userdata">
                    <div className="edit-prfl">
                      <label>
                        Edit
                        <input
                          className="input-file-profile"
                          type="file"
                          accept="image/*"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="schoold-details">
                  <div className="profile-info-container">
                    <label className="profile-label">Name:</label>
                    <input type="text" className="profile-data-div" value={name} onChange={(e) => setName(e?.target?.value)} />
                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">Username:</label>
                    <input type="text" className="profile-data-div" value={username} onChange={(e) => setUsername(e?.target?.value)} disabled />
                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">Email:</label>
                    <input type="text" className="profile-data-div" value={email} onChange={(e) => setEmail(e?.target?.value)} />
                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">Mobile No:</label>
                    <input type="text" className="profile-data-div" value={mobile} onChange={(e) => setMobile(e?.target?.value)} disabled />
                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">D.O.B:</label>
                    <input type="date" className="profile-data-div" value={dob} onChange={(e) => setDob(e?.target?.value)} max={new Date().toISOString().split("T")[0]} />
                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">State:</label>
                    <Select options={statedata} value={stateId}
                      placeholder="Select State" classNamePrefix="profile_select"
                      onChange={(val) => {
                        setStateId(val); setcityId(""); setSchoolId("");
                        setSchoolData([]); setClassData([])
                      }}
                    />

                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">City:</label>
                    <Select options={city} onChange={(val) => { setcityId(val); setSchoolId(""); setClassData([]) }}
                      value={cityId} placeholder="Select City" classNamePrefix="profile_select" />

                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">School:</label>
                    <Select options={schooldata} onChange={(val) => { setSchoolId(val) }}
                      value={schoolId} placeholder="Select School" classNamePrefix="profile_select" />

                  </div>
                  <div className="profile-info-container">
                    <label className="profile-label">Class:</label>
                    <Select onChange={(val) => {
                      setClassid(val);
                    }}
                      options={classdata} value={classId} placeholder="Select Class" classNamePrefix="profile_select" />

                  </div>
                </div>
              </div>
            </div>
            <div className="profile-update-button-div">
              <button
                type="submit"
                className="fl-btn st-d custom_profile_buttons_latest"
                onClick={() => navigate("/changepassword")}>
                <span className="inner">Change Password</span>
              </button>
              <button
                type="submit"
                className="fl-btn st-d custom_profile_buttons_latest"
                onClick={() => updateProfile()}>
                <span className="inner">Update</span>
              </button>


            </div>
          </div>
        </section >
        <Footer />
      </div >
    </>
  );
};
export default Profile;
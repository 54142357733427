// import logo from './logo.svg';
import '../src/Components/Common/css/style.css';
import '../src/Components/Common/css/shortcodes.css'
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-bootstrap';
// import Header from './Components/Header/Header';
// import Login from './Components/Login/Login';
import Signup from './pages/Signup/Signup';
import Otp from './pages/Otp/Otp';
import Changepassword from './pages/ChangePassword/ChangePassword';
import ForgotPassword from './pages/ForgetPassword/ForgotPassword';
import SchoolDetails from './pages/SchoolDetail/SchoolDetails';
import Profile from './pages/Profile/Profile';
import Quiz from './pages/Quiz/Quiz';
import Exam from './pages/Exam/Exam';
// import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import DashboardNew from './pages/DashboardNew/DashboardNew';
import PayProcess from './Components/paying/payprocess';
import MobileVersion from './pages/MobileVersion/MobileVersion';
// import Animation from './Components/Animation/Animation';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path='/dashboard' element={<Dashboard/>}/> */}
        <Route path='/dashboard' element={<DashboardNew />} />
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/otp' element={<Otp />} />
        <Route path='/changepassword' element={<Changepassword />} />
        <Route path='/SchoolDetails' element={<SchoolDetails />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/quiz' element={<Quiz />} />
        <Route path='/exam' element={<Exam />} />
        <Route path='/11-12' element={<Login />} /> {/* level 3 */}
        <Route path='/5-8' element={<Login />} /> {/* level 1 */}
        <Route path='/9-10' element={<Login />} /> {/* level  2 */}
        <Route path='/payprocess' element={<PayProcess />} />
        <Route path='/mobileVersion' element={<MobileVersion />} />
        {/* <Route path='/Animation' element={<Animation />} /> */}
      </Routes>
    </div>
  );
}
export default App;

